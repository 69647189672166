.header,
.darkheader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0;
    margin: 0;
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 1200;
    /* border-bottom: 1px solid #C2C2C2; */
}

.dark .header {
    background-color: black;
}

.darkheader {
    position: fixed;
    top: 0;
    align-items: center;
    background-color: black;
    z-index: 1200;
    color: white;
    /* border-bottom: 1px solid #3a3a3a; */
}

.darkheader>img {
    width: 3em;
    height: 3em;
}

.header>h3 {
    width: 60%;
}

.emtdv {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.emtdv a {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#settings {
    width: 1em;
    height: 1em;
    color: black;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

#settings:active {
    transform: scale(0.96);
}

#darksettings {
    width: 1em;
    height: 1em;
    color: white;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

#darksettings:active {
    transform: scale(0.96);
}

.liloffline {
    display: flex;
    align-items: center;
}

.lilofflinewrt {
    font-weight: 600;
    font-size: 12px;
}

#lilicon {
    height: .8em;
    width: .8em;
    margin: 5px;
}