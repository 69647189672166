.loader {
    position: relative;
    margin: 0 auto;
}

.loader38 {
    height: 38px;
    width: 38px;
}

.loader34 {
    height: 34px;
    width: 34px;
}

.loader28 {
    height: 28px;
    width: 28px;
}

.loader26 {
    height: 26px;
    width: 26px;
}

.circular {
    display: block;
    animation: rotate 2s linear infinite;
    height: 100%;
    transform-origin: center center;
    width: 100%;
    position: relative;
}

.path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
}

@keyframes rotate {
    100% {
        transform: rotate(360deg);
    }
}

@keyframes dash {
    0% {
        stroke-dasharray: 1, 200;
        stroke-dashoffset: 0;
    }

    50% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -35px;
    }

    100% {
        stroke-dasharray: 89, 200;
        stroke-dashoffset: -124px;
    }
}

@keyframes color {

    100%,
    0% {
        stroke: #439CEB;
    }

    40% {
        stroke: #439CEB;
    }

    66% {
        stroke: #439CEB;
    }

    80%,
    90% {
        stroke: #439CEB;
    }
}


.floatingBarsBase {
    position: relative;
}

.iosfloating38 {
    width: 38px;
    height: 38px;
}

.floatingBarsG {
    position: relative;
    width: 80%;
    height: 100%;
}

.blockG {
    position: absolute;
    background-color: #FFFFFF;
    width: 15%;
    height: 32%;
    -webkit-transform: scale(0.4);
    -webkit-animation-name: fadeG;
    -webkit-animation-duration: 0.48s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-direction: linear;
    border-radius: 50% / 20%;
    transform: scale(0.4);
    animation-name: fadeG;
    animation-duration: 0.48s;
    animation-iteration-count: infinite;
    animation-direction: linear;
}

.dark .blockG {
    background-color: #000;
    -webkit-animation-name: darkFadeG;
    animation-name: darkFadeG;
}

#rotateG_01 {
    left: 0;
    top: 36%;
    -webkit-animation-delay: 0.18s;
    -webkit-transform: rotate(-90deg);
    animation-delay: 0.18s;
    transform: rotate(-90deg);
}

#rotateG_02 {
    left: 12.5%;
    top: 12%;
    -webkit-animation-delay: 0.24s;
    -webkit-transform: rotate(-45deg);
    animation-delay: 0.24s;
    transform: rotate(-45deg);
}

#rotateG_03 {
    left: 42.5%;
    top: 4%;
    -webkit-animation-delay: 0.3s;
    -webkit-transform: rotate(0deg);
    animation-delay: 0.3s;
    transform: rotate(0deg);
}

#rotateG_04 {
    right: 12.5%;
    top: 12%;
    -webkit-animation-delay: 0.36s;
    -webkit-transform: rotate(45deg);
    animation-delay: 0.36s;
    transform: rotate(45deg);
}

#rotateG_05 {
    right: 0;
    top: 36%;
    -webkit-animation-delay: 0.42000000000000004s;
    -webkit-transform: rotate(90deg);
    animation-delay: 0.42000000000000004s;
    transform: rotate(90deg);
}

#rotateG_06 {
    right: 12.5%;
    bottom: 10%;
    -webkit-animation-delay: 0.48s;
    -webkit-transform: rotate(135deg);
    animation-delay: 0.48s;
    transform: rotate(135deg);
}

#rotateG_07 {
    bottom: 0;
    left: 42.5%;
    -webkit-animation-delay: 0.5399999999999999s;
    -webkit-transform: rotate(180deg);
    animation-delay: 0.5399999999999999s;
    transform: rotate(180deg);
}

#rotateG_08 {
    left: 12.5%;
    bottom: 10%;
    -webkit-animation-delay: 0.6s;
    -webkit-transform: rotate(-135deg);
    animation-delay: 0.6s;
    transform: rotate(-135deg);
}

@-webkit-keyframes fadeG {
    0% {
        background-color: #000;
    }

    100% {
        background-color: #fff;
    }

}

@keyframes fadeG {
    0% {
        background-color: #000;
    }

    100% {
        background-color: #fff;
    }
}

@-webkit-keyframes darkFadeG {
    0% {
        background-color: #fff;
    }

    100% {
        background-color: #000;
    }

}

@keyframes darkFadeG {
    0% {
        background-color: #fff;
    }

    100% {
        background-color: #000;
    }
}