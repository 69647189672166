html {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
}

html {
  position: relative;
  /*font-size: 10px;*/
  display: flex;
  justify-content: center;
  align-items: center;
}

body {
  display: block;
  position: relative;
  line-height: 1.25;
  font-weight: 500;
  background-color: #fff;
  color: #000;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  font-family: 'Public Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.dark body {
  background-color: #000;
  color: #fff;
}

.userbalancecardbox svg.noticeInfoSvg {
  width: 16px;
  height: 16px;
  fill: #000;
}

.dark .userbalancecardbox svg.noticeInfoSvg {
  fill: #fff;
}

.userbalancecard.userbalancecard-info {
  padding-top: 10px;
  padding-bottom: 10px;
}

.userbalancecard.userbalancecard-info .userbalancecardbox {
  margin-top: 0;
}

/* New CSS Heading*/
.backupmodal * {
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.backupmodal *:focus {
  outline: none;
}

.backupmodal *,
.backupmodal *::before,
.backupmodal *::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  position: relative;
}



a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: currentColor;
}

button,
a {
  cursor: pointer;
}

body::-webkit-scrollbar-thumb {
  border-radius: 9px;
  cursor: pointer;
  background: rgba(90, 96, 124, 0.5);
  display: none;
}

body::-webkit-scrollbar {
  width: 9px;
  background: transparent;
  display: none;
}

/* New CSS Heading stop*/

#root,
#root>div {
  width: 100%;
  height: 100%;
}

.desksidebarheader .arrowback:hover {
  opacity: 0.6;
}

.dark .desksidebarheader .arrowback:hover {
  opacity: 0.75;
}

.arrowback svg {
  height: 14px;
  width: auto;
  fill: #000;
}

.dark .arrowback svg {
  fill: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dark .prlw {
  background-color: #000;
  z-index: 999900;
}

.prlw {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: all;
  transition: all 0.5s;
  z-index: 999900;
}

body.preloading {
  overflow: hidden;
  overflow-y: hidden;
}







/*New CSS*/

small {
  color: #000;
}

.dark small {
  color: #fff;
}

@media all and (orientation: landscape) {

  .page-content.cbprofile-board {
    padding-top: 10px;
  }
}

@media all and (aspect-ratio: 1/1) {
  .page-content.cbprofile-board {
    padding-top: 10px;
  }
}


.cbsettings-general {
  display: block;
  width: 100%;
  margin: 0;
  margin-top: 10px;
}

.cbsettings-general-text,
.cbsettings-about-text {
  display: block;
  margin: 10px 0 0 10px;
  font-weight: 700;
  font-size: 13px;
  text-align: left;
}

.cbsettings-about {
  display: block;
  width: 100%;
  padding: 20px 0 10px 0;
}

.cbsettings-about-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  width: 100%;
  opacity: 1;
  margin: 0;
  margin-top: 5px;
  padding: 5px 0;
  color: #000;
  text-decoration: none;
}

.dark .cbsettings-about-item {
  color: #fff;
}

.cbsettings-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: 1;
  width: 100%;
  flex-shrink: 0;
  margin-top: 5px;
  padding: 10px 0 20px 0;
  color: #000;
  text-decoration: none;
}

.cbsettings-item.cbsettings-itemxxx {
  width: 90%;
  max-width: 350px;
  margin: 0 auto;
  border: 1.5px solid #c2c2c2;
  border-radius: 10px;
}

.dark .cbsettings-item.cbsettings-itemxxx {
  border: 1.5px solid #3a3a3a;
}

.dark .cbsettings-item {
  color: #fff;
}

.cbsettings-about-item:hover {
  opacity: 0.8;
}

.cbsettings-item-tab {
  display: flex;
  justify-content: left;
  align-items: center;
  text-decoration: none;
}

.cbsettings-item-text {
  display: block;
}

.cbsettings-item-title {
  font-size: 16px;
  font-weight: 600;
}

.cbsettings-item-subscript {
  font-size: 13px;
  font-weight: 500;
  opacity: 0.5;
  margin-top: 3px;
}

.cbsettings-item-go {
  height: 40px;
  width: 20px;
  margin: 0 5px 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cbsettings-item-go svg {
  height: 15px;
  width: 15px;
  opacity: 0.5;
  fill: #000;
}

.dark .cbsettings-item-go svg {
  fill: #fff;
}

.cbsettings-item-icon {
  height: 40px;
  width: 40px;
  margin: 0 5px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cbsettings-item-icon svg {
  height: 20px;
  width: 20px;
  fill: #000;
}

.dark .cbsettings-item-icon svg {
  fill: #fff;
}

.dark .qr-container,
.dark .qrbtc-container {
  background: #3b3e46;
  border-top: 1px solid #3a3a3a;
}

.auto-hidden {
  visibility: hidden;
}

.qrbtc-container,
.qr-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  bottom: -100%;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: #ededed;
  list-style: none;
  -webkit-font-smoothing: antialiased;
  transition: bottom 0.6s cubic-bezier(0.77, 0.2, 0.05, 1.0);
  z-index: 10001;
}

.qr-release.qr-container {
  bottom: 0;
}

.btcqr-release.qrbtc-container {
  bottom: 0;
}


body.menu-release {
  overflow-y: hidden;
}

body.nncok {
  overflow-y: scroll;
}

body.ynncok {
  overflow-y: hidden;
}

body.menu-release.nncok {
  overflow-y: hidden;
}

img:hover .menu,
svg:hover .menu {
  box-shadow: 0px 10px 5px rgb(100, 187, 241, 0.1);
}

.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 40px;
  height: 40px;
  top: 13px;
  right: 24px;
  border-radius: 12px;
  background: rgb(255, 255, 255);
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.1);
  z-index: 200;
}

.dark .menu {
  background: #333740;
}

.furtherformydata {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: relative;
  color: #333740;
  font-size: 13px;
  font-weight: 500;
  width: 100%;
  margin: 0;
  border-radius: 18px;
  padding: 0;
}

.dark .furtherformydata {
  color: #fff;
}

.border-form__button span {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  z-index: 9;
}

.border-form__button {
  width: 100%;
  height: 45px;
  border: 2px solid #2364d2;
  border-radius: 18px;
  font-size: 14px;
  font-weight: 500;
  color: #326cd1;
  cursor: pointer;
}

.formy-form__button span {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  z-index: 9;
  color: #fff;
}

.formy-form__button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  background: #2364d2;
  border: none;
  border-radius: 18px;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
}

.cpbnb span {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  z-index: 9;
}

.dark .cpbnb {
  color: #8ca6fa;
}

.cpbnb {
  width: 0;
  height: 0;
  background: none;
  border: 2px solid #2364d2;
  border-radius: 18px;
  font-size: 12px;
  font-weight: 500;
  color: #326cd1;
  cursor: pointer;
}

.cpbn span {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  color: #fff;
  margin: 0;
  padding: 0;
  z-index: 9;
}

.cpbn {
  width: 0;
  height: 0;
  background: #2364d2;
  border: none;
  border-radius: 18px;
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  cursor: pointer;
}

#svbn {
  font-size: 11px;
  color: #222429;
  margin-top: 5px;
}

#svbn:hover {
  color: #4f4de7;
}

.dark .cbsccss {
  stroke: #fff;
}

.cbsccss {
  fill: none;
  stroke: #000;
  margin: 15px auto 5px auto;
  width: 110px;
  height: 110px;
}

.nopndcn.qrview .qcrbd .pndcn {
  display: none;
  width: 0;
  height: 0;
}

.dark .pndcn {
  fill: #fff;
}

.pndcn {
  display: none;
  stroke: none;
  fill: #000;
  overflow: hidden;
  margin: 0;
  width: 0;
  height: 0;
}

#qrcode {
  margin-top: 10px;
}

.qcrbd .gntcns {
  padding: 0 15px;
}

.dark .qcrbd #ccptcn {
  background-color: #ffffffc7;
  color: #1a1a1a;
}

.dark .qcrbd #gntcn.gntcn {
  border: 2px solid #ffffffc7;
}

.qcrbd #ccptcn {
  width: 60px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000000a1;
  color: #ffffff;
  font-size: 12px;
  margin-top: 0;
}

.qcrbd #gntcn.gntcn {
  width: auto;
  max-width: 280px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  text-align: center;
  margin: 0;
  opacity: 1;
  word-break: break-all;
  font-size: 15px;
  margin-top: 0;
  border-radius: 10px;
  border: 2px solid #000000a1;
}

#rtddp.gntcn {
  max-width: 220px;
  opacity: 1;
  word-break: break-all;
  font-size: 15px;
}

.qcrbd p.gntcn {
  opacity: 1;
  word-break: break-all;
  font-size: 17px;
}

#formyapproveone.formy-form__button {
  width: 70%;
  margin: 0 auto;
}

.fview .formy-details {
  opacity: 1;
  z-index: 20;
}

.dark .qrview .zztcn {
  background: #333740;
}

.zztcn p {
  line-height: 1.2;
}

.zztcn {
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  vertical-align: middle;
  border-radius: 28px;
  background: rgb(255, 254, 254);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  transition: 0.6s ease-in;
}

#sztcn {
  z-index: 1;
}

.eqsztcn#sztcn {
  opacity: 1;
  z-index: 20;
}

#pztcn {
  z-index: 2;
}

.eqpztcn#pztcn {
  opacity: 1;
  z-index: 20;
}

#nztcn {
  z-index: 3;
}

.eqnztcn#nztcn {
  opacity: 1;
  z-index: 20;
}

#iztcn {
  z-index: 3;
}

.eqiztcn#iztcn {
  opacity: 1;
  z-index: 20;
}

.dark .qrview .qcrbd {
  background: #333740;
  border: 1px solid #3a3a3a;
}

.notificationmodalcontainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 96%;
  max-width: 380px;
  padding: 15px;
  margin: 0;
  position: relative;
  border-radius: 28px;
  background: rgb(245, 245, 245);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  transition: 0.6s ease-in;
  z-index: 2;
  border: 1px solid #c2c2c2;
}

.backupmodalcontainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 96%;
  max-width: 380px;
  padding: 15px;
  overflow: hidden;
  margin: 0;
  position: relative;
  border-radius: 28px;
  background: rgb(245, 245, 245);
  color: #393939;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  transition: 0.6s ease-in;
  z-index: 2;
  border: 1px solid #c2c2c2;
}

.dark .notificationmodalcontainer {
  background: #333740;
  border: 1px solid #3a3a3a;
  color: #dddddd;
}

.dark .backupmodalcontainer {
  background: #333740;
  border: 1px solid #3a3a3a;
  color: #dddddd;
}

.notificationmodalcontainer p {
  display: block;
  width: 100%;
  overflow: hidden;
  font-size: 14px;
  text-align: center;
  opacity: 0.7;
  margin: 0;
  margin: 12px auto 16px auto;
  padding: 0 10px;
}


.page-top-duo-content-bar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.backupmodalcontainer p {
  display: block;
  width: 100%;
  overflow: hidden;
  font-size: 14px;
  text-align: center;
  opacity: 0.7;
  margin: 0;
  margin: 18px auto 0 auto;
  padding: 0 10px;
}

.notificationmodalcontainer svg {
  width: 120px;
  height: auto;
}

.backupmodalcontainer svg {
  width: 110px;
  height: auto;
  margin: 10px auto 0 auto;
}

.specsvgpath {
  fill: #3e404d;
}

.dark .specsvgpath {
  fill: #fff;
}

.dark .specsvgpath2 {
  fill: #94d5f1;
}

.specsvgpath2 {
  fill: #5555ed;
}

.qcrbd {
  opacity: 0;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 96%;
  max-width: 350px;
  padding: 0;
  margin: 0;
  position: absolute;
  vertical-align: middle;
  border-radius: 28px;
  background: rgb(245, 245, 245);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  transition: 0.6s ease-in;
  z-index: 2;
  border: 1px solid #c2c2c2;
}

.ndsvg {
  height: 100px;
  width: 100px;
  margin: 20px 0 10px 0;
}

.qcrbd p.wcvdg {
  display: block;
  width: 230px;
  line-height: 1.4;
  overflow: hidden;
  font-size: 14px;
  text-align: center;
  opacity: 0.7;
  margin: 0;
}

.bcrtn {
  width: 220px;
  margin: 6px 0 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dark .qcrbd h4.ozbsd {
  color: #fff;
}

.qcrbd h4.ozbsd {
  color: #222;
  margin: 0 0 10px 0;
}

.qcrbd p {
  display: block;
  width: 90%;
  overflow: hidden;
  font-size: 14px;
  color: #000;
  text-align: center;
  opacity: 0.7;
  margin: 0;
}

.dark .qcrbd p {
  color: #fff;
}

.qcrbd.racsqq {
  justify-content: space-evenly;
}

.qcrbd p.qcrbdpq {
  display: block;
  width: 250px;
  overflow: hidden;
  font-size: 13px;
  text-align: center;
  opacity: 0.7;
  margin: 0;
}

.qrview .qcrbd {
  display: flex;
  margin: 0 auto;
  padding: 20px 0;
  opacity: 1;
  z-index: 20;
}

.qrview .qcrbd p {
  margin: 12px auto 16px auto;
  padding: 0 10px;
}

.qrview .qcrbd p.qcrbdpq {
  margin: 5px auto 10px auto;
}

.qrview .qcrbd p.wcvdg {
  margin: 12px auto 25px auto;
}

.qrview .qcrbd .pndcn {
  display: block;
  width: 110px;
  height: 110px;
  margin: 15px auto 5px auto;
}

.qrview .qcrbd #gntcn.gntcn {
  margin: 12px auto 22px auto;
  margin-top: 0;
}

.qrview .qcrbd .cpbn {
  width: 100px;
  height: 30px;
}

.qrview .qcrbd .cpbnb {
  width: 100px;
  height: 30px;
}

.notificationmodalbuttons {
  width: 100%;
  max-width: 300px;
  margin: 0 auto 20px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.backupmodalbuttons {
  width: 100%;
  max-width: 300px;
  margin: 0 auto 20px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.closenotificationmodal {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 43%;
  margin-left: 2%;
  height: 30px;
  font-size: 14px;
  border: 2px solid #184fee;
  color: #295cf7;
  border-radius: 15px;
}

.closebackupmodal {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 43%;
  margin-left: 2%;
  height: 30px;
  font-size: 12px;
  border: 2px solid #184fee;
  color: #295cf7;
  border-radius: 15px;
}

.closenotificationmodal:hover,
.closenotificationmodal:active {
  color: #295cf7;
}

.closebackupmodal:hover,
.closebackupmodal:active {
  color: #295cf7;
}

.fullnotificationmodal {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #184fee;
  color: #fff;
  width: 43%;
  margin-right: 2%;
  height: 30px;
  font-size: 14px;
  border: 2px solid #184fee;
  border-radius: 15px;
}

.fullbackupmodal {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #184fee;
  color: #fff;
  width: 43%;
  margin-right: 2%;
  height: 30px;
  font-size: 12px;
  border: 2px solid #184fee;
  border-radius: 15px;
}

.fullnotificationmodal:hover,
.fullnotificationmodal:active {
  color: #fff;
}

.fullbackupmodal:hover,
.fullbackupmodal:active {
  color: #fff;
}

.retrieveview .retrieveboard {
  opacity: 1;
  z-index: 20;
}

.cbbutton-margin {
  margin: 0;
  margin-top: 10px;
}

.formy-form__button.buttongrey {
  background: #5a5d6e;
}

.formy-form__button.buttongrey .formy-form__buttonbefore {
  visibility: hidden;
  opacity: 0;
  background: #5a5d6e;
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: inherit;
  overflow: hidden;
  transition: all .25s ease;
  z-index: 15;
}

.formy-form__buttonbefore {
  visibility: hidden;
  opacity: 0;
  background: #2364d2;
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: inherit;
  overflow: hidden;
  transition: all .25s ease;
  z-index: 15;
}

.buttonloading.formy-form__button.buttongrey .formy-form__buttonbefore {
  visibility: visible;
  opacity: 1;
}

.buttonloading.formy-form__button .formy-form__buttonbefore {
  visibility: visible;
  opacity: 1;
}

.formy-form__button span.buttonbeforespan1 {
  width: 8px;
  height: 8px;
  background: #fff;
  border-radius: 50%;
  animation: loading4 1s ease infinite;
  position: absolute;
}

.formy-form__button span.buttonbeforespan2 {
  width: 8px;
  height: 8px;
  background: #fff;
  border-radius: 50%;
  animation: loading4 1s ease infinite .33s;
  position: absolute;
}

.formy-form__button span.buttonbeforespan3 {
  width: 8px;
  height: 8px;
  background: #fff;
  border-radius: 50%;
  animation: loading4 1s ease infinite .66s;
  position: absolute;
}

@keyframes loading4 {
  0% {
    transform: translate(-40px) scale(0);
  }

  50% {
    transform: translate(0px) scale(1);
  }

  100% {
    transform: translate(40px) scale(0);
  }
}

.buttonloading.formy-form__button {
  overflow: hidden;
  transform: translateZ(0);
}

.page-content.cbprofile-board {
  width: 100%;
  padding: 0;
  padding-top: 30px;
  overflow-x: hidden;
  overflow-y: scroll;
  /*max-width: 750px;*/
}

.page-content.cbprofile-board::-webkit-scrollbar-thumb {
  display: none;
}

.page-content.cbprofile-board::-webkit-scrollbar {
  display: none;
}

.mainTabView {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
}

.mainTabView::-webkit-scrollbar {
  display: none;
}

#page-top-bar-profile.page-top-bar {
  width: 100%;
}

.formyboard {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 580px;
  padding: 0;
  opacity: 0;
  position: absolute;
  vertical-align: middle;
  z-index: 2;
}

.formyone {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /*max-width: 410px;*/
  min-height: 360px;
  padding: 14px 0;
  position: absolute;
  vertical-align: middle;
  transition: 0.6s ease-in;
}

.cubby.formyfour {
  width: 100%;
  /*max-width: 410px;*/
  min-height: 320px;
}

.requestcubby.formyfour {
  min-height: 580px;
  width: 100%;
  /*max-width: 410px;*/
}

.formyfour {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /*max-width: 410px;*/
  min-height: 250px;
  position: absolute;
  vertical-align: middle;
  transition: 0.6s ease-in;
}

.helplink:hover {
  color: #4dd1d1;
}

.helplink {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-weight: 500;
  font-size: 13px;
  text-decoration: none;
  padding: 20px 0 0 0;
  color: #167ff0;
  vertical-align: middle;
}

.helplink:hover {
  color: #4dd1d1;
}

.termstext {
  display: block;
  text-align: left;
  width: 100%;
  font-weight: 500;
  font-size: 11px;
  text-decoration: none;
  margin: 10px 0 0 0;
  padding: 0 0 0 5px;
}

.lolinkSent {
  display: none;
  align-items: center;
  justify-content: left;
  width: 100%;
  text-align: left;
  font-weight: 500;
  font-size: 13px;
  text-decoration: none;
  margin: 20px 0 0 0;
  padding: 0 0 0 5px;
}

.lolinkTimer {
  text-decoration: none;
  margin: 0 0 0 4px;
  font-weight: 700;
  color: #167ff0;
}

.stats-loading {
  display: none;
  width: 28px;
  height: 6px;
  position: relative;
  margin: -12px 0 0 5px;
}

.stats-loading div {
  background: #000000;
  display: inline-block;
  height: 4px;
  width: 4px;
  border-radius: 100%;
  -webkit-animation: bouncedelay 1.4s infinite ease-in-out;
  animation: bouncedelay 1.4s infinite ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}


.dark .stats-loading div {
  background: #ffffff;
}

.stats-loading div.eins {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.stats-loading div.zwei {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes bouncedelay {

  0%,
  80%,
  100% {
    transform: scale(0);
    opacity: 0;
  }

  40% {
    transform: scale(1);
    opacity: 100;
  }
}

@keyframes bouncedelay {

  0%,
  80%,
  100% {
    transform: scale(0);
    opacity: 0;
  }

  40% {
    transform: scale(1);
    opacity: 100;
  }
}

.lolink {
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  text-align: left;
  font-weight: 500;
  font-size: 13px;
  text-decoration: none;
  margin: 20px 0 0 0;
  padding: 0 0 0 5px;
}

.lolink a {
  text-decoration: none;
  margin: 0 0 0 2px;
  color: #167ff0;
}

.lolinkbtn {
  text-decoration: none;
  margin: 0 0 0 3px;
  color: #167ff0;
}

.lolinkbtn:hover {
  color: #4dd1d1;
}

.termstext a:hover {
  color: #4dd1d1;
}

.termstext a {
  text-decoration: none;
  color: #167ff0;
}

.onnotificationCountBar {
  padding: 2px 6px;
  border-radius: 15px;
  flex-shrink: 0;
  color: #fff;
  background-color: red;
  margin: -14px -30px 0 0;
  border: 2px solid #fff;
  font-size: 10px;
  position: absolute;
  z-index: 5;
}

.dark .onnotificationCountBar {
  border: 4px solid #000;
  color: #fff;
}

#notificationCountBar {
  display: none;
}

#notificationCountBar.onnotificationCountBar {
  display: block;
}

.dark .formythree {
  background: #333740;
}

.formythree {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /*max-width: 500px;*/
  min-height: 380px;
  padding: 20px 14px;
  position: absolute;
  vertical-align: middle;
  border-radius: 28px;
  background: rgb(255, 254, 254);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  transition: 0.6s ease-in;
}

.formypool-item {
  display: block;
  list-style: none;
  padding: 0 8px;
  margin: 0 auto;
  min-height: 580px;
  /*max-width: 500px;*/
  width: 100%;
}

.formypool-item.qrview {
  display: flex;
  align-items: center;
  justify-content: center;
}

.formone .formone {
  opacity: 1;
  z-index: 20;
}

.nextform .nextform {
  opacity: 1;
  z-index: 20;
}

.formtwo .formtwo {
  opacity: 1;
  z-index: 20;
}

.formthree .formthree {
  opacity: 1;
  z-index: 20;
}

.formyapprove .formyview {
  opacity: 1;
  z-index: 20;
}

.preformyview {
  display: block;
  opacity: 0;
  min-height: 580px;
  width: 100%;
  top: 0;
  left: 0;
  position: absolute;
  transition: 0.6s ease-in;
  z-index: 2;
}

.formytoken-title-icon svg {
  height: 20px;
  width: 20px;
  border-radius: 50%;
}

.formytokenicon-background {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 22px;
  width: 22px;
  background-color: #d8d8d8;
  border-radius: 50%;
}

.formytoken-title {
  margin: 0 5px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  text-overflow: ellipsis;
  padding: 5px 0;
  overflow: hidden;
  white-space: nowrap;
}

.formytoken-title:hover {
  color: #000;
}

.formytoken-title-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.formy-tokenbox svg {
  transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.formy-tokenbox svg,
.formy-tokenbox span {
  pointer-events: none;
}

.formy-tokenbox:hover,
.formy-tokenbox:focus {
  color: #ffffff;
}

.dark .formy-tokenbox {
  border: 0.1rem solid #494d59;
  background-color: #333740;
  color: #b1b8ca;
}

.formy-tokenbox {
  width: 100%;
  border-radius: 1.4rem;
  color: #000;
  font-size: 15px;
  text-transform: uppercase;
  border: 0.15rem solid #dbdde0;
  background: transparent;
  transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  display: flex;
  align-items: center;
  padding: 5px 9px;
  margin: 0 0 7px 0;
}

.formy-tokenbox:hover {
  color: #000;
}

.dark .formy-tokenbox:hover {
  color: #fff;
}

.formy-tokenbox:focus {
  border: 0.1rem solid #2c62f6;
  box-shadow: 0 0 0 0.2rem rgba(44, 98, 246, 0.4);
}

.formy-form__inner {
  display: none;
  min-height: 580px;
  width: 100%;
  /*max-width: 410px;*/
  padding: 0;
  opacity: 0;
  z-index: 2;
}

.fview .formy-form__inner {
  display: block;
  opacity: 1;
  z-index: 20;
}

#mailverify-board {
  display: none;
  min-height: 580px;
  width: 100%;
  /*max-width: 410px;*/
  padding: 0;
  opacity: 0;
  z-index: 2;
}

.vvview #mailverify-board {
  display: block;
  opacity: 1;
  z-index: 20;
}

.formy-form__inner.farm__inner {
  min-height: 580px;
}

.totalvalueformyd {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 8px;
  width: 100%;
  height: 18px;
}

.switch-toggle__label {
  position: relative;
  cursor: pointer;
}

.switch-toggle__input {
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  transform: translate(-50%, -50%);
}

.switch-toggle__input:focus+.switch-toggle__design {
  box-shadow: 0 0 0 0.1rem hsl(200, 79%, 16%), 0 0 0 0.2rem hsl(200, 79%, 46%);
}

.switch-toggle {
  margin: 0;
}

.swapsetting__input div {
  line-height: 1.5;
  font-size: 13px;
  height: 20px;
}

.dark .swapsetting__input input {
  color: #fff;
}

.swapsetting__input input {
  text-align: center;
  height: 20px;
  width: 18px;
  border: 0;
  padding: 0;
  font-size: 13px;
  font-weight: 500;
  color: #000;
  background: transparent;
}

.txdl .swapsetting__input {
  padding: 3px 9px 3px 9px;
}

.dark .swapsetting__input {
  color: #fff;
  background: hsl(0, 0%, 20%);
}

.swapsetting__input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60px;
  height: 3rem;
  overflow: hidden;
  padding: 3px 15px;
  font-size: 14px;
  font-weight: 500;
  color: #000;
  border-radius: 100rem;
  background: #e0dfe6;
  position: relative;
  transition: 0.2s, box-shadow 0s;
}

.switch-toggle__design {
  display: inline-block;
  width: 6rem;
  height: 3rem;
  border-radius: 100rem;
  background: #d0cedc;
  position: relative;
  transition: 0.2s, box-shadow 0s;
}

.dark .switch-toggle__design {
  background: hsl(0, 0%, 20%);
}

.switch-toggle__design .switch-toggle__designball {
  position: absolute;
  left: .6rem;
  top: 50%;
  transform: translateY(-50%);
  width: 22px;
  height: 22px;
  border-radius: 100rem;
  background: hsl(0, 0%, 65%);
  transition: inherit;
}

.switch-toggle .switch-toggle__design {
  transition: 0.3s, box-shadow 0s;
}

.switch-toggle__input:checked+.switch-toggle__design {
  border-color: transparent;
  background: hsl(200, 79%, 16%);
}

.switch-toggle__input:checked+.switch-toggle__design .switch-toggle__designball {
  left: calc(100% - (22px + .6rem));
  background: hsl(200, 79%, 46%);
}

.switch-toggle .switch-toggle__input:checked+.switch-toggle__design .switch-toggle__designball {
  left: calc(100% - .6rem);
  transform: translate(-100%, -50%);
}

.switch-toggle:active .switch-toggle__designball {
  width: calc(22px + .4rem);
}

.dot {
  animation: dot-anim 8s linear infinite;
}

/* Animations */

@keyframes dot-anim {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes left-edge {
  0% {
    transform: rotate(0deg);
  }

  35% {
    transform: rotate(-25deg);
  }

  75% {
    transform: rotate(25deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes buttonloading {
  0% {
    transform: translateX(25px);
  }

  100% {
    transform: translateX(-20px);
  }
}

.press {
  cursor: pointer;
  text-decoration: none;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.press:active {
  transform: scale(0.92);
}

button {
  border: none;
  cursor: pointer;
  background-color: transparent;
  outline: none;
}

.dark .onnotice .rednot {
  border: 2px solid #333740;
}

.onnotice .rednot {
  width: 10px;
  height: 10px;
  top: 19px;
  right: 32px;
  border: 2px solid #fff;
  border-radius: 50%;
  position: absolute;
  z-index: 90;
  opacity: 1;
  background-color: #e13c42;
}

button {
  border: none;
  cursor: pointer;
  background-color: transparent;
  outline: none;
}

.text-truncate {
  display: flex;
  justify-content: center;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.dark .mbheader {
  color: #fff;
}

.dark .qcrbd h4 {
  /*color: #65bc5e;*/
  color: #ffffff;
}

.dark .h4#szhd {
  color: #65bc5e;
}

.qcrbd h4 {
  font-size: 18px;
  font-weight: 700;
  /*color: #26a71b;*/
  color: #000;
  margin: 20px 0 0 0;
}

#szhdt {
  margin: 0 4px 0 0;
}

h4#szhd span {
  display: flex;
  justify-content: center;
  align-items: center;
}

h4#szhd {
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  color: #26a71b;
  margin: 0 0 0 0;
}

.dark .twelveheader {
  color: #fff;
}

.twelveheader {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  text-align: left;
  opacity: 0.75;
  font-size: 14px;
  width: 100%;
  padding: 5px 15px 18px 15px;
  transition: all 0.2s;
}

.farm-tokenbox span {
  line-height: 1;
}

.notification-dot {
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 9px;
  padding: 3.5px 6.5px;
  line-height: 1;
  color: #fff;
  border: 3px solid #fff;
  margin: -25px -20px 0 0;
  border-radius: 12px;
  position: absolute;
  z-index: 5;
}

.dark .notification-dot {
  border: 3px solid #000;
}

.no-result-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  width: 95%;
  max-width: 650px;
  margin: 0 auto;
  padding: 0 16px 0 16px;
}

.no-result-svg {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  max-width: 400px;
  width: 100%;
}

.no-result-svg svg {
  width: 50%;
  height: auto;
  margin: 0 0 30px 0;
}

.no-result-found h2 {
  width: 90%;
  margin: 0 auto;
  text-align: center;
  opacity: 0.8;
  font-size: 15px;
  font-weight: 500;
  color: #000;
}

.dark .no-result-found h2 {
  color: #fff;
}

.notification-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding: 16px;
  margin: 0 auto 10px auto;
  border-radius: 16px;
  border: 1px solid #c2c2c2;
  color: #000;
  background-color: rgba(6, 29, 110, 0.08);
}

.dark .notification-container {
  color: #fff;
  background-color: rgba(100, 100, 100, 0.3);
  border: 1px solid #3a3a3a;
}

@media screen and (min-width: 650px) {
  .notification-container {
    width: 95%;
    margin: 0 auto 12px auto;
  }
}

.notification-header {
  opacity: 0.75;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 8px;
  font-weight: 500;
  font-size: 12px;
}

.notification-header h2 {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
}

.notification-timestamp {
  text-transform: lowercase;
}

.notification-content span {
  display: block;
  line-height: 1.4;
}

.notification-message {
  overflow: hidden;
  font-size: 15px;
  width: 100%;
}

.notification-content .event {
  font-weight: 600;
}

.notifisvg path {
  stroke-width: 1px;
  stroke: #fff;
}

.dark .notifisvg path {
  stroke: #000;
}

.rembheader span {
  opacity: 0.7;
}

.rembheader {
  display: block;
  font-weight: 500;
  text-align: left;
  font-size: 12.5px;
  margin: 0 0 15px 0;
  width: 100%;
  padding: 0 0 0 5px;
  transition: all 0.2s;
}

.dark .rembheader {
  color: #fff;
}

.mbheader {
  display: block;
  text-align: left;
  font-weight: 600;
  font-size: 14px;
  width: 100%;
  /*padding: 0 5px 0 5px;*/
  transition: all 0.2s;
}

.mbheader .mbsubscript {
  display: block;
  font-weight: 500;
  text-align: left;
  font-size: 12.5px;
  opacity: 0.75;
  margin: 0;
  width: 100%;
  padding: 0;
}

.dark .card-input {
  border: 2px solid #ffffff27;
  color: #ececec;
  background: #000000;
}

.qrSerial {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #222;
  box-shadow: none;
  font-size: 11px;
  font-weight: 700;
  height: 16px;
  width: 100%;
  margin: 12px 0 0 0;
  transition: all 0.3s ease-in-out;
}

.dark .qrNature {
  color: #eee;
}

.qrNature {
  display: block;
  text-align: center;
  color: #21242b;
  opacity: 0.8;
  box-shadow: none;
  font-size: 13px;
  padding: 0 40px;
  width: 100%;
  margin: 4px 0;
  transition: all 0.3s ease-in-out;
}

.dark .supertitle {
  color: #eee;
}

.hiddenInput {
  width: 0;
  height: 0;
  text-decoration: none;
  list-style: none;
  border-width: 0;
}

.supertitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: #21242b;
  box-shadow: none;
  font-size: 13px;
  padding: 0 9px;
  width: 100%;
  margin: 4px 0;
  transition: all 0.3s ease-in-out;
}

.dark .innerMessage,
.dark .inputMessage {
  color: #ff4747;
}

.innerMessage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: #ff0000;
  box-shadow: none;
  font-size: 11px;
  padding: 0 18px;
  margin: 5px 0;
  width: 100%;
  height: 15.5px;
  transition: all 0.3s ease-in-out;
}

.inputMessage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  color: #ff0000;
  box-shadow: none;
  font-size: 11px;
  padding: 0 18px;
  width: 100%;
  margin: 2px 0;
  transition: all 0.3s ease-in-out;
}

.recard {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f0f0f0;
  border-radius: 12px;
  box-shadow: none;
  border: 1px solid #6363631f;
  padding: 5px 15px;
  height: 90px;
  width: 100%;
  margin: 10px 0 7px 0;
  transition: all 0.3s ease-in-out;
}

.recard:hover {
  border-color: #2c62f6;
  box-shadow: 0 0 0 0.2rem rgb(44 98 246 / 40%);
}

.ccard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
}

.cbcard-input {
  display: block;
  box-shadow: none;
  width: 100%;
  margin-top: 18px;
}

.confirmpassinput.cbcard-input {
  margin: 0 0 10px 0;
  margin-top: 15px;
}

.cbcard-input-title {
  display: block;
  text-align: left;
  font-size: 12px;
  font-weight: 700;
  margin: 0 0 5px 5px;
}

.dark .cbwithdraw-info {
  border: 2px solid #ffffff27;
  color: #ececec;
}

.cbwithdraw-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background: transparent;
  color: #21242b;
  border-radius: 10px;
  box-shadow: none;
  border: 1.5px solid #4d4d4d60;
  padding: 10px 15px;
  width: 100%;
  margin: 0 auto;
  transition: all 0.3s ease-in-out;
}

.mbheader #page-top-bar {
  padding: 150px 10px 0 10px;
}

#page-top-bar {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex-shrink: 0;
  width: 100%;
  /*max-width: 750px;*/
  padding: 60px 16px 0 16px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

#page-top-bar.trader-page-top-bar {
  /*max-width: 750px;*/
}

@media all and (orientation: landscape) {
  #page-top-bar {
    padding: 60px 16px 0 16px;
  }

  .mbheader #page-top-bar {
    padding: 60px 16px 0 16px;
  }
}

@media all and (aspect-ratio: 1/1) {
  #page-top-bar {
    padding: 60px 16px 0 16px;
  }

  .mbheader #page-top-bar {
    padding: 60px 16px 0 16px;
  }
}

.logout-container,
.page-content {
  width: 100%;
  /*max-width: 750px;*/
  padding: 20px 16px 0 16px;
  margin: 0 auto;
}

.page-content {
  display: block;
  position: relative;
  overflow: hidden;
  opacity: 1;
  color: #333740;
  font-size: 13px;
  font-weight: 500;
}

.no-result-found {
  min-height: calc(100vh - 146px);
}

@media screen and (min-width: 360px) {
  .page-content {
    padding: 20px 16px 0 16px;
  }
}

.dark .page-content {
  color: #fff;
}

.dark .cbhistory-card {
  color: #fff;
}

.page-content.trader-page-content {
  display: block;
  width: 100%;
  /*max-width: 750px;*/
  min-height: 100%;
}

.cbhistory-card {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background: transparent;
  color: #000;
  font-weight: 600;
  box-shadow: none;
  padding: 5px 5px;
  width: 100%;
  margin: 5px auto;
  transition: all 0.3s ease-in-out;
}

.cbhistory-card-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 85%;
  transition: all 0.3s ease-in-out;
}

.cbhistory-card-sub {
  display: flex;
  flex-direction: row;
  opacity: 0.7;
  font-weight: 500;
  margin: 2px 0 0 0;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  transition: all 0.3s ease-in-out;
}

.dark .cbhistory-circle {
  opacity: 1;
}

.cbhistory-circle {
  display: block;
  background-color: #000;
  width: 4px;
  height: 4px;
  margin: 0 0 0 5px;
  opacity: 0.6;
  border: 0;
  border-radius: 50%;
}

.card-input {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  background: #ffffff;
  color: #21242b;
  border-radius: 10px;
  box-shadow: none;
  border: 1.5px solid #4d4d4d60;
  padding: 5px 15px;
  width: 100%;
  margin: 0 auto;
  transition: all 0.3s ease-in-out;
}

.dark .card-input div {
  font-weight: 500;
  opacity: 0.5;
}

.card-input div {
  font-weight: 800;
  margin: 0 0 5px 0;
  opacity: 0.5;
}

.card-input__input {
  width: 100%;
  height: 36px;
  font-size: 14px;
  font-weight: 500;
  color: inherit;
  background: none;
  outline: none;
  border: 0;
  padding: 5px 0;
}

.card-input:hover,
.dark .tags:hover {
  border-color: #2c62f6;
  box-shadow: 0 0 0 0.2rem rgb(44 98 246 / 40%);
}

.card-input:focus {
  border-color: #2c62f6;
  box-shadow: 0 0 0 0.2rem rgb(44 98 246 / 40%);
}

.dark .formysearch-box span {
  background: #21242b;
}

.formysearch-box span {
  height: 30px;
  width: 40px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ccced7;
  margin: 0 0 0 5px;
  position: relative;
}

.dark .staketokenicon-background {
  background-color: rgb(201, 198, 198);
}

.staketokenicon-background {
  height: 30px;
  width: 30px;
  display: flex;
  margin: 0 10px 0 0;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #d6d6d6;
}

.dark .stake-tokenbox {
  color: rgb(131, 131, 131);
  border: 1px solid rgb(114, 114, 114);
}

.stake-tokenbox {
  padding: 3px 15px 3px 3px;
  border-radius: 26px;
  display: flex;
  color: #858585;
  border: 1px solid #d6d6d6;
  justify-content: center;
  align-items: center;
}

.dark .logtextbox {
  color: #fff;
}

.logtextbox {
  padding: 0;
  font-size: 28px;
  display: block;
  font-weight: 800;
  text-align: left;
  color: #1a1a1a;
}

.dark .resettextbox {
  color: #fff;
}

.resettextbox {
  padding: 3px 0;
  font-size: 18px;
  text-align: left;
  display: block;
  opacity: 1;
}

.resettextbox span {
  font-weight: 700;
  opacity: 1;
}

.app-backlink {
  display: none;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  padding: 5px 0;
  opacity: 1;
  margin: 0;
}

.qr-backlink {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  padding: 0;
  opacity: 1;
  margin: 0;
}

.tmpbar .app-backlink {
  display: flex;
}

.app-backlink:hover {
  opacity: 0.7;
}

.qr-backlink:hover {
  opacity: 0.7;
}

.qr-backlink svg {
  margin: 0;
  fill: #000;
}

.dark .qr-backlink svg {
  fill: #fff;
}

.app-backlink svg {
  height: 14px;
  width: auto;
  margin-right: 10px;
  fill: #000;
}

.dark .app-backlink svg {
  fill: #fff;
}

#app-pagetitle {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  opacity: 1;
  color: #000000;
  font-size: 16px;
  margin: 0;
}

.tmpbar #app-pagetitle {
  display: flex;
}

.dark #app-pagetitle {
  color: #fff;
}

.backlink-container {
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  padding: 0;
  margin: 0 0 10px 0;
}

.backlink {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  padding: 5px 8px;
  opacity: 1;
  color: #000000;
  font-size: 13px;
  margin: 0 0 20px 0;
}

.backlink:hover {
  opacity: 0.7;
}

.dark .backlink {
  color: #fff;
}

.backlink:hover {
  color: #000;
}

.dark .backlink:hover {
  color: #fff;
}

.backlink svg {
  height: 14px;
  width: auto;
  margin-right: 5px;
  fill: #000;
}

.dark .backlink svg {
  fill: #fff;
}

.stake-tokenbox svg {
  height: 25px;
  width: 25px;
  position: relative;
}

@media screen and (max-width: 1050px) {

  .coincontainer {
    padding: 15px 3%;
  }

  .coininfo {
    padding: 15px 3%;
  }

  .coindata {
    padding: 15px 0 15px 3%;
  }

}

@media screen and (max-width: 815px) {

  .coininfo {
    padding: 15px 10%;
  }

}

@media screen and (max-width: 650px) {

  .coininfo {
    padding: 15px 5% 15px 8%;
  }

  .defi-reward {
    font-size: 14px;
  }

  .coindata {
    padding: 15px 8%;
  }

}

@media screen and (min-width: 650px) {

  .formysearch-box {
    width: 70%;
    margin: 5px auto 0 auto;
  }

  .titlemax {
    font-size: 20px;
  }

  .defi-reward {
    font-size: 15px;
  }

  .boldmax {
    font-size: 25px;
  }

  .coincontainer {
    height: 260px;
    width: 50%;
  }

  .coindata {
    height: 260px;
    width: 50%;
  }

  @media screen and (min-width: 760px) {

    .titlemax {
      font-size: 20px;
    }

    .boldmax {
      font-size: 30px;
    }

    .uppercase {
      font-size: 12px;
    }

    @media screen and (min-width: 815px) {

      .sidebars:hover {
        border-radius: 15px;
      }

      .titlemax {
        font-size: 20px;
      }

      .boldmax {
        font-size: 33px;
      }

      .uppercase {
        font-size: 12px;
      }

      .coincontainer {
        height: 260px;
        width: 50%;
      }

      .coininfo {
        width: 50%;
      }

      .coindata {
        width: 50%;
      }

      @media screen and (min-width: 1050px) {

        .coincontainer {
          padding: 15px 7%;
        }

        .coininfo {
          padding: 15px 7%;
        }

        .coindata {
          padding: 15px 7%;
        }

      }

    }

  }

}

.settingstoggle {
  width: 100%;
  height: 37px;
  padding: 0 8px;
  margin-bottom: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toplayer {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.activepool,
.expiredpool,
.mypool {
  display: none;
}

.onlyactivepool .activepool {
  display: flex;
}

.onlyendedpool .expiredpool {
  display: flex;
}

.onlymyvault .expiredpool.mypool {
  display: flex;
}

.onlymyvault .mypool {
  display: flex;
}

.formback {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  margin: 5px 0 0 0;
  padding: 0 12px;
  cursor: pointer;
  border-radius: 12px;
  color: #000;
  background: rgb(255, 255, 255);
  border: 0.1rem solid #b7b7b7;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: 200;
}

.dark .formback {
  color: #fff;
  background: #333740;
  border: 0.1rem solid #494d59;
}

.notification {
  position: relative;
  width: 18.75em;
  font-size: calc(19px + (24 - 16) * (100vw - 320px) / (1280 - 320));
  color: #222;
  font-weight: 600;
  transition: transform 0.15s ease-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.dark .notification {
  color: #fff;
}

.notification__box,
.notification__content,
.notification__btns {
  display: flex;
}

.notification__box,
.notification__content {
  align-items: center;
}

.dark .notification__box {
  background-color: #2a2f3a;
  box-shadow: 0 0.5em 1em hsla(223, 10%, 10%, 0.1);
}

.notification__box {
  background-color: hsl(0, 0%, 100%);
  border-radius: 0.75em;
  overflow: hidden;
  box-shadow: 0 0.5em 1em hsla(223, 10%, 10%, 0.1);
  height: 4em;
}

.notification__content {
  padding: 0.375em 1em;
  width: 100%;
  height: 100%;
}

.notification__icon {
  flex-shrink: 0;
  margin-right: 0.75em;
  width: 2em;
  height: 2em;
}

.notification__icon-svg {
  width: 100%;
  height: auto;
}

.notification__text {
  line-height: 1.333;
}

.notification__text-title {
  font-size: 0.75em;
  margin: 0 0 3px 0;
  font-weight: 700;
}

.notification__text-subtitle {
  font-size: 0.6em;
  font-weight: 500;
  opacity: 0.6;
}

.dark .notification__btns {
  box-shadow: -1px 0 0 hsla(0, 0%, 35%, 0.6);
  transition: box-shadow hsl(0, 0%, 56%, 0.6);
}

.notification__btns {
  box-shadow: -1px 0 0 hsla(223, 10%, 10%, 0.15);
  flex-direction: column;
  flex-shrink: 0;
  min-width: 4em;
  height: 100%;
  transition: box-shadow hsl(223, 10%, 10%);
}

.qrcode canvas {
  width: 200px;
  height: 200px;
}

.qrbody {
  width: 240px;
  height: 240px;
  display: flex;
  margin: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid rgba(153, 153, 153, 0.566);
  background-color: #fff;
  border-radius: 15px;
}

.qrbody-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.qrbody-containerbox {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.qrcode-address {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px auto 0 auto;
  font-size: 16px;
  font-weight: 700;
  color: #00000094;
}

.qrcode-address img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.saveqrbtn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 700;
  color: #2681e2;
  margin: 20px auto 0 auto;
}

.saveqrbtn svg {
  fill: #2681e2;
  stroke: #2681e2;
  height: 20px;
  width: auto;
  margin-right: 5px;
}

.dark .saveqrbtn svg {
  fill: #2681e2;
}

.notification__btn {
  background-color: transparent;
  box-shadow: 0 0 0 hsla(223, 10%, 10%, 0.5) inset;
  font-size: 0.6em;
  line-height: 1;
  font-weight: 600;
  height: 100%;
  padding: 0 0.5rem;
  -webkit-appearance: none;
  appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.dark .notification__btn-text {
  color: #fff;
}

.notification__btn-text {
  display: inline-block;
  pointer-events: none;
}

.notification__btn:only-child {
  border-radius: 0 0.75rem 0.75rem 0;
}

.notification__btn+.notification__btn {
  box-shadow: 0 -1px 0 hsla(223, 10%, 10%, 0.15);
  font-weight: 400;
}

.dark .notification__btn:active,
.dark .notification__btn:focus {
  background-color: rgb(51, 58, 69);
}

.dark .notification__btn:focus {
  outline: transparent;
}

.notification__btn:active,
.notification__btn:focus {
  background-color: hsl(223, 10%, 95%);
}

.notification__btn:focus {
  outline: transparent;
}

#farmstage {
  display: flex;
  width: 100%;
  height: auto;
  margin: 0;
  align-items: center;
  justify-content: center;
}

.farminstruction {
  display: flex;
  width: 100%;
  color: #000;
  font-size: 13px;
  line-height: 1;
  margin: 0 0 8px 0;
  font-weight: 500;
  align-items: center;
  justify-content: center;
}

.dark .farminstruction {
  color: #fff;
}

.epng {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(141, 215, 255);
  border: 3px solid #ffffff;
  margin: 0 0 0 -5px;
  position: relative;
  width: 33px;
  padding: 0;
  height: 33px;
}

.epng img {
  display: block;
  position: relative;
  width: 22px;
  padding: 0;
  height: 22px;
}

.dark .epng {
  border: 2px solid #333740;
}

.tpng {
  border-radius: 50%;
  display: block;
  background-color: #50af95;
  border: 3px solid #ffffff;
  margin: 0 7px 0 -7px;
  position: relative;
  width: 33px;
  padding: 0;
  height: 33px;
}

.dark .tpng {
  border: 2px solid #333740;
}

.bpng {
  border-radius: 50%;
  display: block;
  background-color: rgb(141, 215, 255);
  position: relative;
  margin: 0 -2px 0 0;
  width: 30px;
  padding: 0;
  height: 30px;
}

.zero {
  width: 0;
  height: 0;
  overflow: hidden;
}

.symbolofreward,
.symboloftoken {
  margin: 0 0 0 6px;
}

.dark .notification__btn-text {
  color: #fff;
}

.notification__btn-text {
  color: #000;
}


.userbalancecard {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  width: 90%;
  margin: 10px auto 0 auto;
  border-radius: 15px;
  border: 1px solid #c2c2c2;
  padding: 15px 15px;
  overflow: hidden;

  background: rgba(0, 0, 0, 0.02);
  backdrop-filter: blur(2px);

}

.dark .userbalancecard {
  /*background: linear-gradient(to right bottom, #323941, #061018);*/
  border: 1px solid #3a3a3a;
}

.userbalancecardbox {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  margin: 5px auto 0 auto;
  padding: 0;
  overflow: hidden;
}

.userbalancecardbox svg {
  position: relative;
  display: block;
  flex-shrink: 0;
  width: 40px;
  height: auto;
  margin: 0;
}

.cbcardcontainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  width: 90%;
  margin: 15px auto 0 auto;
  max-height: 260px;
  border-radius: 15px;
  background: linear-gradient(to right bottom, #ededed, #e6ebff);
  border: 1px solid #c2c2c2;
  padding: 15px 15px;
  overflow: hidden;
}

.cbcardsubcontainer.cbcardsubcontainerRight {
  justify-content: right;
}

.cbcardsubcontainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: left;
  width: 100%;
  height: 100%;
  margin: 4px 0 0 0;
  overflow: hidden;
}

.cbcardsubcontainerbutton,
.cbcardsubbtccontainerbutton {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000011;
  border: 1px solid #c2c2c2;
  width: 25px;
  height: 25px;
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
}

.qrEmptBar {
  width: 14px;
  height: 14px;
}

.cbcardsubcontainerbutton.cbcardsubcontainerbutton1 {
  margin-right: 10px;
}

.dark .cbcardsubcontainerbutton,
.dark .cbcardsubbtccontainerbutton {
  background-color: #ffffff11;
  border: 1px solid #3a3a3a;
}

.cbcardsubbtccontainerbutton svg,
.cbcardsubcontainerbutton svg {
  fill: #000;
  width: 18px;
  height: auto;
}

.cbcardsubbtccontainerbutton.copyanimation svg,
.dark .cbcardsubbtccontainerbutton.copyanimation svg,
.cbcardsubcontainerbutton.copyanimation svg,
.dark .cbcardsubcontainerbutton.copyanimation svg {
  fill: #4c73c7;
}

.dark .cbcardsubcontainerbutton svg,
.dark .cbcardsubbtccontainerbutton svg {
  fill: #fff;
}

.dark .cbcardcontainer {
  background: linear-gradient(to right bottom, #323941, #061018);
  border: 1px solid #3a3a3a;
}

.pfp-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #999999;
  background-color: #fff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
}


.dark .pfp-avatar {
  border: 1px solid #777777;
}

.pfp-avatar div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-color: #fff;
}

.pfp-avatar img {
  display: block;
  width: 35px;
  height: 35px;
}

.usercard-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  margin-left: 10px;
}

.cbcardcontainer .cbcard-title {
  font-size: 13px;
  margin: 0;
  color: rgba(255, 255, 255, 0.473);
}

.cbcardcontainer .cbcardtop {
  display: block;
  position: relative;
  font-size: 24.2px;
}

h2.cbcard-balance {
  color: #000000;
  margin: 0;
  font-size: 14px;
  font-weight: 700;
  z-index: 900;
}

.dark h2.cbcard-balance {
  color: #ffffff;
}

.cbcardcontainer .cbcard-mainlogo {
  height: 20px;
  width: auto;
  fill: #ffffffa2;
  position: absolute;
  left: 25px;
  top: 22px;
}

.cbcardcontainer .cbcard-sim {
  width: 30px;
  position: absolute;
  bottom: 20px;
  opacity: 0.5;
}

.cbcardcontainer .cbcard-wallet {
  font-size: 16px;
  opacity: 0.7;
  z-index: 2;
}

.cbcard-wallet-container {
  line-height: 1;
  font-size: 14px;
  opacity: 0.7;
  z-index: 2;
}

.cbcardcontainer .cbcard-username {
  font-weight: 700;
  font-size: 15px;
  text-transform: capitalize;
  z-index: 2;
}

.cbcard-balance-container {
  line-height: 1;
  font-weight: 700;
  font-size: 17px;
  text-transform: capitalize;
  margin-bottom: 3px;
  z-index: 2;
}

.cbcardcontainer .cbcard-logo {
  position: absolute;
  top: -40px;
  right: -50px;
  fill: #235bee;
  opacity: 0.8;
  height: 160px;
  transform: rotate(-12deg);
}

@media (min-width:330px) {
  h2.cbcard-balance {
    font-size: 16px;
  }
}

@media (min-width:370px) {
  h2.cbcard-balance {
    font-size: 21px;
  }
}

@media screen and (min-width: 550px) {
  .cbcardcontainer {
    border-radius: 20px;
    padding: 15px;
  }

  .cbcardcontainer .cbcard-title {
    font-size: 15.4px;
    margin: 0;
  }

  .cbcardcontainer .cbcardtop {
    font-size: 24.2px;
  }

  h2.cbcard-balance {
    margin: 5px 0 0 0;
    font-size: 24.2px;
  }

  .cbcardcontainer .cbcard-mainlogo {
    height: 28px;
    width: auto;
    left: 30px;
    top: 30px;
  }

  .cbcardcontainer .cbcard-sim {
    width: 40px;
    bottom: 28px;
  }

  .cbcardcontainer .cbcard-wallet {
    font-size: 16px;
  }

  .cbcard-wallet-container {
    font-size: 14px;
  }

  .cbcardcontainer .cbcard-username {
    font-size: 15px;
  }

  .cbcard-balance-container {
    font-size: 17px;
  }

  .cbcardcontainer .cbcard-logo {
    top: -40px;
    right: -50px;
    height: 200px;
    transform: rotate(-12deg);
  }
}

@media screen and (max-width: 350px) {
  .cbcardcontainer {
    border-radius: 12px;
    padding: 10px 15px;
  }

  .cbcardcontainer .cbcard-title {
    font-size: 13px;
    margin: 0;
  }

  .cbcardcontainer .cbcardtop {
    font-size: 20px;
  }

  .cbcardcontainer .cbcard-mainlogo {
    height: 18px;
    left: 15px;
    top: 18px;
  }

  .cbcardcontainer .cbcard-sim {
    width: 25px;
    bottom: 15px;
  }

  .cbcardcontainer .cbcard-wallet {
    font-size: 14px;
  }

  .cbcard-wallet-container {
    font-size: 13px;
  }

  .cbcardcontainer .cbcard-username {
    font-size: 13px;
  }

  .cbcard-balance-container {
    font-size: 15px;
  }

  .cbcardcontainer .cbcard-logo {
    top: -40px;
    right: -50px;
    height: 130px;
  }
}

@media screen and (max-width: 260px) {
  .cbcardcontainer {
    border-radius: 10px;
    padding: 5px 10px;
  }

  .cbcardcontainer .cbcard-title {
    font-size: 10px;
    margin: 0;
  }

  .cbcardcontainer .cbcardtop {
    font-size: 15px;
  }

  h2.cbcard-balance {
    margin: 0;
    font-size: 14px;
  }

  .cbcardcontainer .cbcard-mainlogo {
    height: 14px;
    left: 10px;
    top: 12px;
  }

  .cbcardcontainer .cbcard-sim {
    width: 20px;
    bottom: 12px;
  }

  .cbcardcontainer .cbcard-wallet {
    font-size: 12px;
  }

  .cbcard-wallet-container {
    font-size: 12px;
  }

  .cbcardcontainer .cbcard-username {
    font-size: 11px;
  }

  .cbcard-balance-container {
    font-size: 13px;
  }

  .cbcardcontainer .cbcard-logo {
    top: -40px;
    right: -50px;
    height: 110px;
  }
}

#cbwserver-wallet {
  word-break: break-all;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-red {
  background-color: #e74c3c !important;
}

.bg-orange {
  background-color: #e67e22 !important;
}

.bg-green {
  background-color: #2ecc71 !important;
}

body .password-wrapper {
  width: 100%;
  position: relative;
}

body .password-wrapper .icon-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 36px;
  margin: 0;
  cursor: pointer;
}

body .password-wrapper .card-input {
  padding: 5px 5px 5px 15px;
}

/*body .password-wrapper .card-input input.card-input__input {
	max-width: 410px;
}*/

body .password-wrapper .icon-wrapper svg {
  width: 20px;
  height: 20px;
  fill: #000;
  transition: fill 0.25s ease-out;
  display: none;
  cursor: pointer;
}

.toggle-password {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.dark body .password-wrapper .icon-wrapper svg {
  fill: #fff;
}

body .password-wrapper .icon-wrapper .ion-eye .ion-view {
  display: block;
}

body .password-wrapper .icon-wrapper .ion-hide {
  display: block;
}

body .password-wrapper .icon-wrapper .ion-eye .ion-hide {
  display: none;
}

body .password-wrapper .strength-lines {
  position: relative;
  margin-top: 8px;
  width: 70%;
  height: 6px;
  z-index: 3;
}

body .password-wrapper .strength-lines .line {
  position: absolute;
  background-color: transparent;
  height: 3px;
  border-radius: 2px;
  transition: background-color 0.25s ease-in;
}

body .password-wrapper .strength-lines .line:not(:first-of-type):not(:last-of-type) {
  left: 33%;
  right: 33%;
}

body .password-wrapper .strength-lines .line:first-of-type {
  left: 4px;
  right: 68%;
}

body .password-wrapper .strength-lines .line:last-of-type {
  left: 68%;
  right: 4px;
}

.dark .notificationmodalcontainer {
  background-color: #222429;
}

.dark .backupmodalcontainer {
  background-color: #222429;
}

.notificationmodal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background-color: #00000042;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  pointer-events: all;
  transition: all 0.5s;
  z-index: 9990;
}

.backupmodal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background-color: #00000042;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  visibility: visible;
  opacity: 1;
  transition: all 0.5s;
  /*z-index: 9999;*/
  z-index: 10001;
}

body.opbpmodal,
body.qr-release,
body.btcqr-release {
  overflow: hidden;
  overflow-y: hidden;
}

/*Mob Views*/
.arrowBackContainer {
  display: block;
}

.androidView .arrowBackContainer {
  width: 20px;
  height: 20px;
}

.iosview .arrowBackContainer {
  width: 20px;
  height: 20px;
}

.androidBackArrow,
.iosBackArrow {
  fill: #000;
  width: 100%;
  height: 100%;
}

.dark .androidBackArrow,
.dark .iosBackArrow {
  fill: #fff;
}

.androidView .androidBackArrow {
  display: block;
}

.iosview .iosBackArrow {
  display: block;
}

.iosview .androidBackArrow {
  display: none;
}

.androidView .iosBackArrow {
  display: none;
}

.innerheader h3,
.innerheader2 h3 {
  font-size: 17px;
  font-weight: 500;
}

.innerheader2 {
  border-bottom: 1px solid #C2C2C2;
}

.dark .innerheader2 {
  border-bottom: 1px solid #3a3a3a;
}

.androidView .innerheader,
.androidView .innerheader2,
.androidView .app-header,
.androidView .app-header-scrolled,
.androidView .qr-header {
  height: 56px;
  padding: 12px 16px 12px 16px;
}

.androidView .stickedbar {
  top: 54px;
}

.iosview .innerheader,
.iosview .innerheader2,
.iosview .app-header,
.iosview .app-header-scrolled,
.iosview .qr-header {
  height: 44px;
  padding: 9px;
}

.iosview .stickedbar {
  top: 42px;
}


.innerheader,
.innerheader2,
.app-header,
.app-header-scrolled,
.qr-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-shrink: 0;
}

.app-header {
  background-color: #fff;
  width: 100%;
  position: relative;
  z-index: 199;
}

.dark .app-header {
  background-color: #000;
}

.app-header-scrolled {
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 199;
}

.dark .app-header-scrolled {
  background-color: #000;
}

.app-header-left,
.app-header-right {
  display: flex;
  align-items: center;
  justify-content: center;
}

.qr-header {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 199;
}

.qr-header-title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #000;
}

.dark .qr-header-title {
  color: #fff;
}

.qr-header-title svg {
  height: 18px;
  width: auto;
  font-weight: 500;
  margin-right: 3px;
}

.usdt-only-title .tether-qr-title {
  display: flex;
}

.tether-qr-title {
  display: flex;
}

.eth-only-title .ethereum-qr-title {
  display: flex;
}

.ethereum-qr-title {
  display: flex;
}




.qr-warning-box {
  padding-top: 70px;
  flex-shrink: 0;
}

.qr-warning.qr-warning4 {
  display: flex;
  flex-direction: row;
  background-color: #3333333f;
  color: #555;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  border-radius: 12px;
  padding: 18px;
  width: 92%;
  flex-shrink: 0;
  max-width: 300px;
  margin: 0 8px 10px 8px;
}

.qr-warning.qr-warning4.resetLockWarning {
  align-items: flex-start;
}

.dark .qr-warning.qr-warning4 {
  background-color: #bbbbbb3f;
  color: #bbb;
}

.qr-warning {
  display: flex;
  flex-direction: row;
  background-color: #fff;
  color: #555;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  border-radius: 12px;
  padding: 18px;
  width: 92%;
  flex-shrink: 0;
  max-width: 300px;
  margin: 0 8px 10px 8px;
}

.dark .qr-warning {
  background-color: #0000003f;
  color: #bbb;
}

.qr-warning div {
  display: block;
  font-size: 12px;
  width: 90%;
  max-width: 230px;
}

.qr-warning span {
  margin-right: 3px;
  font-weight: 700;
  font-size: 13px;
  color: #000000;
}

.dark .qr-warning span {
  color: #ffffff;
}

.qr-warning svg {
  fill: #000;
  margin: -25px 10px 0 0;
  width: 20px;
  height: auto;
}

.qr-warning.resetLockWarning svg {
  margin: 0 10px 0 0;
}

.qr-warning .qr-warning1 svg {
  margin: -35px 10px 0 0;
}

.dark .qr-warning svg {
  fill: #fff;
}