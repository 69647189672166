.setting {
    width: 100%;
    display: block;
    overflow-y: scroll;
}

.settingbod {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    /*max-width: 750px;*/
    display: block;
    overflow: hidden;
    overflow-y: scroll;
}

.settingbod .press {
    cursor: pointer;
    text-decoration: none;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.settingbod .press:active {
    transform: scale(0.98);
}

.settingbod .page-content {
    min-height: calc(100vh - 172px);
}


.logout-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 72px;
}

.settingbod::-webkit-scrollbar-thumb {
    border-radius: 9px;
    cursor: pointer;
    background: rgba(90, 96, 124, 0.5);
    display: none;
}

.settingbod::-webkit-scrollbar {
    width: 9px;
    background: transparent;
    display: none;
}

.settingbox {
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 4rem;
}

svg.topbarSettingsIcon {
    fill: #000;
    width: 24px;
    height: 24px;
}

.dark svg.topbarSettingsIcon {
    fill: #fff;
}

.emptnot>h3 {
    margin-bottom: .5em;
    margin-top: .5em;
    width: 90%;
    font-size: 20px;
    font-weight: 500;
    color: rgb(172, 172, 172);
}

.settinglist {
    width: 90%;
    padding-left: 1em;
    padding-top: .5em;
    padding-bottom: .5em;
}

.nonedisp1 {
    padding: 30px;
}

#nonedisp {
    display: none;
}

.nonedisp {
    display: none;
}

.settinglist>p {
    color: grey;
    opacity: .8;
    margin-top: .5em;
    margin-bottom: .5em;
    text-transform: capitalize;
}

#seticons {
    width: .7em;
    height: .7em;
}

.logoutbtn {
    width: 90%;
    display: flex;
    align-items: center;
    padding-top: 1em;
    padding-bottom: 1em;
    justify-content: center;
    background-color: #2cda8b;
    margin-top: 2em;
    margin-bottom: 2em;
    border-radius: 10px;
}

#togs {
    width: 1.5em;
    height: 1.5em;
}

.toggle {
    color: rgb(4, 185, 4);
}

.sett {
    height: 95vh;
}

.switchList,
.switchList li {
    list-style: none;
    padding: 0;
    margin: 0;
}

.switchList li {
    padding: 10px 0;
    /* margin-left: 10px; */
    /* margin-right: 10px; */
}

.switchList li:last-child {
    border-bottom: 1px solid transparent;
}


/* Android Toggle Switch */

.android-switch {
    display: none;
    position: relative;
}

.android-switch+label .small {
    font-size: 0.8em;
    color: #999;
    line-height: 1.5em;
    display: block;
}

.android-switch+label {
    display: block;

    /* padding: 10px; */
    text-align: left !important;
    /* To keep the switch from getting misaligned */
}

.android-switch+label span.sw {
    display: inline-block;
    width: 31px;
    height: 15px;
    border-radius: 8px;
    margin-right: 10px;
    margin-left: 10px;
    background-color: #b2b2b2;
}

.android-switch+label span.sw:before {
    content: '';
    position: absolute;
    background-color: #eee;
    margin-top: -4px;
    margin-left: -6px;
    height: 23px;
    width: 23px;
    border-radius: 15px;
    transition: all ease 300ms;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
}

.android-switch:checked+label span.sw {
    background-color: #b4ddd9;
}

.android-switch:checked+label span:before {
    margin-left: 14px;
    background-color: #009582;
}


/* iOS Toggle Switch */

.ios-switch {
    display: none;
    position: relative;
}

.ios-switch+label .small {
    font-size: 0.8em;
    color: #999;
    line-height: 1.5em;
    display: block;
}

.ios-switch+label {
    display: block;

    /* padding: 10px; */
    text-align: left !important;
    /* To keep the switch from getting misaligned */
}

.ios-switch+label span.sw {
    display: inline-block;
    width: 46px;
    height: 27px;
    border-radius: 16px;
    margin-right: 10px;
    margin-left: 10px;
    border: 2px solid #f1f0f0;
    background-color: #f1f0f0;
    transition: all ease 300ms;
}

.ios-switch+label span.sw:before {
    content: '';
    position: absolute;
    background-color: #fff;
    margin-top: .5px;
    margin-left: 0px;
    height: 23px;
    width: 23px;
    border-radius: 15px;
    transition: all ease 300ms;
}

.ios-switch:checked+label span.sw {
    background-color: #49d864;
    border: 2px solid #49d864;
}

.ios-switch:checked+label span:before {
    margin-left: 19px;
    background-color: #fff;
}


/* Windows Toggle Switch */

.win-switch {
    display: none;
    position: relative;
}

.win-switch+label .small {
    font-size: 0.8em;
    color: #999;
    line-height: 1.5em;
    display: block;
}

.win-switch+label {
    display: block;

    /* padding: 10px; */
    text-align: left !important;
    /* To keep the switch from getting misaligned */
}

.win-switch+label span.sw {
    display: inline-block;
    width: 41px;
    height: 17px;
    border-radius: 11px;
    margin-right: 10px;
    margin-left: 10px;
    background-color: transparent;
    border: 2px solid #333;
    transition: all ease 300ms;
}

.win-switch+label span.sw:before {
    content: '';
    position: absolute;
    background-color: #333;
    margin-top: 1px;
    margin-left: 2px;
    height: 11px;
    width: 11px;
    border-radius: 15px;
    transition: all ease 300ms;
}

.win-switch:checked+label span.sw {
    background-color: #0078d7;
    border-color: #0078d7;
}

.win-switch:checked+label span:before {
    margin-left: 26px;
    background-color: #fff;
}

.glnked {
    padding-right: .1px;
}

.editpay {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 4rem;
    align-items: center;
}

.editpay>.nothd {
    width: 95%;
    max-width: 80%;
}

.settingbod .path {
    animation: dash 1.5s ease-in-out infinite, white-loader-color 6s ease-in-out infinite;
}

@keyframes white-loader-color {

    100%,
    0% {
        stroke: #fff;
    }

    40% {
        stroke: #fff;
    }

    66% {
        stroke: #fff;
    }

    80%,
    90% {
        stroke: #fff;
    }
}

.dark .settingbod .blockG,
.settingbod .blockG {
    background-color: #5a5d6e;
    -webkit-animation-name: buttonfadeG;
    animation-name: buttonfadeG;
}

@-webkit-keyframes buttonfadeG {
    0% {
        background-color: #5a5d6e;
    }

    100% {
        background-color: #fff;
    }

}

@keyframes buttonfadeG {
    0% {
        background-color: #5a5d6e;
    }

    100% {
        background-color: #fff;
    }
}