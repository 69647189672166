.lockcell {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin: 0 auto;
    padding: 55px 0 0 0;
    background-color: transparent;
    text-align: center;
}

.passcode-reset-link {
    flex-shrink: 0;
    padding: 5px 16px 30px 16px;
    font-weight: 500;
    opacity: 0.6;
}

#pincode {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 999999999999;
    margin: 0 auto;
    padding: 0;
    background-color: #fff;
    color: #222;
    text-align: center;
}

#pincode * {
    overflow: hidden;
}

.dark #pincode {
    background-color: #000;
    color: #fff;
}

#lockNumbers {
    width: 100%;
    max-width: 280px;
    padding: 10px 20px 0 20px;
    margin: 0 auto;
    position: relative;
    display: block;
    -webkit-transition: all 1s ease-out;
    -moz-transition: all 1s ease-out;
    transition: all 1s ease-out;
    opacity: 1;
}

#lockNumbers.lockNumbersHide {
    opacity: 0.3;
}

.lockNumbPad div {
    width: 70px;
    height: 70px;
}

#pincode button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    height: 70px;
    margin-bottom: 10px;
    border: 0;
    color: #222;
    font-size: 30px;
    font-weight: 500;
    border-radius: 100%;
    opacity: 1;
    outline: 0;
}

.dark #pincode button {
    color: #fff;
}


.dark.iosview #pincode button {
    background-color: #45484f;
}

.iosview #pincode button {
    background-color: #cecece;
}

.androidView #pincode button {
    background-color: transparent;
}

#pincode .emptyLockNumbPad button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
}

.dark #pincode .emptyLockNumbPad button {
    background-color: transparent;
}

.dark.androidView #pincode .emptyLockNumbPad button:active,
.dark.iosview #pincode .emptyLockNumbPad button:active {
    background-color: transparent;
}

.dark #pincode .lockNumbPadGreen button,
#pincode .lockNumbPadGreen button {
    background-color: #3eba2e;
}

#pincode .emptyLockNumbPad button:active {
    background-color: transparent;
}

.androidView #pincode button:active,
.iosview #pincode button:active {
    background-color: #bbb;
    outline: 0;
}

.dark.iosview #pincode button:active,
.dark.androidView #pincode button:active {
    background-color: rgb(103, 107, 114);
}

#lockScreenFields {
    width: 100%;
    max-width: 200px;
    padding: 0 20px;
    margin: 20px auto 35px auto;
    position: relative;
    display: block;
}

#lockScreenFields .lockNumbfield {
    text-align: center;
}

#lockScreenFields .lockNumbfield span {
    height: 10px;
    width: 10px;
    border: 2px solid #222;
    background-color: transparent;
    border-radius: 100%;
    position: relative;
    display: inline-block;
    text-align: center;
}

.dark #lockScreenFields .lockNumbfield span {
    border: 2px solid #fff;
}

#lockScreenFields .lockNumbfield.pinCodeActive span {
    background-color: #222;
}

.dark #lockScreenFields .lockNumbfield.pinCodeActive span {
    background-color: #fff;
}

#lockScreenFields .lockNumbfield.right span {
    background-color: #45484f;
    border-color: #45484f;
    transition: all .5s ease-in-out;
    -webkit-transition: all .5s ease-in-out;
}

.lockgrid {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
}

.lockInputBox,
.lockGridCol--2-of-8,
.lockGridCol--3-of-12 {
    width: 25%;
}

.lockGridCol {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
}

.lockGridCol--centered {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.lockGridCol--d-first {
    float: left;
}

.lockGridCol--d-last {
    float: right;
}

.lockgrid--no-gutter {
    margin-left: 0;
    width: 100%;
}

.lockgrid--no-gutter .lockGridCol {
    padding-left: 0;
}

.dark .lockGridCol .svgPathX {
    fill: #fff;
    background-color: #222;
}

.dark .lockGridCol svg {
    fill: #45484f;
}

.lockGridCol .svgPathX {
    fill: #222;
}

.lockGridCol svg {
    fill: #cecece;
    height: 30px;
    width: 30px;
}

.dark #pincode .lockNumbPadGreen button svg,
#pincode .lockNumbPadGreen button svg {
    fill: #fff;
}

#pincode button:active svg,
#pincode button:active svg {
    fill: #bbb;
}

.dark #pincode button:active svg,
.dark #pincode button:active svg {
    fill: rgb(103, 107, 114);
}

#pincode button:active,
#pincode button:active {
    background-color: #bbb;
    outline: 0;
}

.screenPadlockSvg {
    width: 16px;
    height: 16px;
    fill: #222;
    margin: 10px auto;
}

.dark .screenPadlockSvg {
    fill: #fff;
}

.miss {
    -webkit-animation: miss .8s ease-out 1;
    animation: miss .8s ease-out 1;
}

@-webkit-keyframes miss {
    0% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }

    10% {
        -webkit-transform: translate(-25px, 0);
        transform: translate(-25px, 0);
    }

    20% {
        -webkit-transform: translate(25px, 0);
        transform: translate(25px, 0);
    }

    30% {
        -webkit-transform: translate(-20px, 0);
        transform: translate(-20px, 0);
    }

    40% {
        -webkit-transform: translate(20px, 0);
        transform: translate(20px, 0);
    }

    50% {
        -webkit-transform: translate(-10px, 0);
        transform: translate(-10px, 0);
    }

    60% {
        -webkit-transform: translate(10px, 0);
        transform: translate(10px, 0);
    }

    70% {
        -webkit-transform: translate(-5px, 0);
        transform: translate(-5px, 0);
    }

    80% {
        -webkit-transform: translate(5px, 0);
        transform: translate(5px, 0);
    }

    100% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
}

@keyframes miss {
    0% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }

    10% {
        -webkit-transform: translate(-25px, 0);
        transform: translate(-25px, 0);
    }

    20% {
        -webkit-transform: translate(25px, 0);
        transform: translate(25px, 0);
    }

    30% {
        -webkit-transform: translate(-20px, 0);
        transform: translate(-20px, 0);
    }

    40% {
        -webkit-transform: translate(20px, 0);
        transform: translate(20px, 0);
    }

    50% {
        -webkit-transform: translate(-10px, 0);
        transform: translate(-10px, 0);
    }

    60% {
        -webkit-transform: translate(10px, 0);
        transform: translate(10px, 0);
    }

    70% {
        -webkit-transform: translate(-5px, 0);
        transform: translate(-5px, 0);
    }

    80% {
        -webkit-transform: translate(5px, 0);
        transform: translate(5px, 0);
    }

    100% {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
    }
}