#root {
    width: 100%;
    height: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
    position: relative;
}

.rootchild {
    width: 100%;
    height: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
    position: relative;
}

.desktchat {
    width: 100%;
    height: 100%;
    padding: 10px 10px 15px 10px;
    display: flex;
    position: relative;
    overflow: hidden;
}

.desksidebar {
    width: 40%;
    /*height: 100%;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

@media screen and (min-width: 1100px) {
    .desksidebar {
        width: 35%;
    }
}

.androidView .desksidebarcontainer.desksidebar {
    padding-top: 4px;
}

.iosview .desksidebarcontainer.desksidebar {
    padding-top: 16px;
}

.desksidebarheader {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.userhdinfocontainer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 5px 10px 0 0;
    width: 100%;
}

.userhdinfomobcontainer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 5px 0 0 0;
    width: 100%;
}

.userhdinfo {
    display: flex;
    align-items: center;
    position: relative;
    background-color: #0000001b;
    border-radius: 30px;
    border: 1px solid #00000018;
    width: 100%;
    padding: 10px 16px;
    text-decoration: none;
    color: #000;
    z-index: 2;
}

.sidebars img {
    border-radius: 50%;
    border: 1.5px solid #0000008f;
}

.dark .sidebars img {
    border: 1.5px solid #ffffff8f;
}

.desktchatbodhd img {
    border-radius: 50%;
    border: 1.5px solid #0000008f;
}

.dark .desktchatbodhd img {
    border: 1.5px solid #ffffff8f;
}

.userhdinfo img {
    border-radius: 50%;
    border: 1px solid #0000008f;
}

.dark .userhdinfo img {
    border: 1px solid #ffffff8f;
}

.sidebars .avatarbod {
    border: 1.5px solid #0000001b;
}

.dark .sidebars .avatarbod {
    border: 1.5px solid #ffffffb3;
}

.desktchatbodhd .avatarbod {
    border: 1.5px solid #0000001b;
}

.dark .desktchatbodhd .avatarbod {
    border: 1.5px solid #ffffffb3;
}

.userhdinfo .avatarbod {
    border: 1px solid #0000008f;
}

.dark .userhdinfo .avatarbod {
    border: 1px solid #ffffff8f;
}

.dark .userhdinfo {
    background-color: #ffffff1b;
    border: 1px solid #ffffff18;
}

.dark .userhdinfo {
    color: #fff;
}

.mob-userhdtop.userhdtop {
    padding: 8px 12px 0 12px;
}

.androidView .userhdtop {
    height: 56px;
    padding: 12px 16px 12px 16px;
}

.iosview .userhdtop {
    height: 44px;
    padding: 9px;
}

.userhdtop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    text-decoration: none;
    background-color: #fff;
    color: #000;
}

@media only screen and (min-width: 800px) {

    .iosview .userhdtop,
    .androidView .userhdtop {
        padding-top: 0;
    }
}

.dark .userhdtop {
    background-color: #000;
    color: #fff;
}

.connectionPlaceholder {
    display: block;
    width: 30px;
    height: 30px;
}

h1.page-big-title {
    width: 100%;
    text-align: left;
    font-size: 28px;
    font-weight: 700;
}

.iosview h1.page-big-title {
    padding: 16px 16px 8px 16px;
}

.androidView h1.page-big-title {
    padding: 4px 16px 10px 16px;
}

.userhdinfowrt {
    margin-left: 12px;
}

.desktchatbodhduser .userhdinfowrt {
    margin-left: 8px;
}

.desktchatbodhduser h3 {
    font-size: 19px;
    line-height: 1;
}

.userhdinfowrt>p {
    font-size: 12px;
    color: grey;
}

.innersrchbar {
    position: relative;
}

.gensrchhd {
    width: 100%;
    position: sticky;
    top: -2px;
    z-index: 2000;
    background-color: #fff;
}

.dark .gensrchhd {
    background-color: #000;
}

.gensrchhd~.sidebars {
    width: 97%;
    margin: 0 auto;
}

.gensrchhd~.sidebars:hover {
    border-radius: 10px;
}

.desksrchinput.srchinput {
    padding-right: 8px;
}

.srchinput {
    width: 100%;
    padding: 0 16px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.srchinput>h3 {
    font-size: 25px;
    font-weight: 600;
    margin-bottom: 1rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.srchinput>input {
    width: 80%;
    font-size: 15px;
    padding: 10px;
    border-radius: 5px;
    outline-width: 0;
    border: 2px solid grey;
    zoom: 1;
}

.sidebarChatContainer {
    height: 100%;
    width: 100%;
    overflow-y: scroll;
    z-index: 200;
}

.sidebarChatContainer::-webkit-scrollbar-thumb {
    border-radius: 6px;
    cursor: pointer;
    background: rgba(90, 96, 124, 0.5);
    display: none;
}

.sidebarChatContainer::-webkit-scrollbar {
    width: 8px;
    background: transparent;
    display: none;
}

.sidebarchats {
    width: 100%;
    z-index: 200;
}


.sidebarchats .emptcont {
    min-height: calc(100vh - 236px);
}

.userStatusWrapper {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 40px;
    margin-left: -10px;
}

.userOnlineStatus,
.userOfflineStatus {
    display: block;
    flex-shrink: 0;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: 3px solid #fff;
}

.sidebars:hover .userOnlineStatus,
.sidebars:hover .userOfflineStatus {
    border: 3px solid #f7f7f7;
}

.dark .sidebars:hover .userOnlineStatus,
.dark .sidebars:hover .userOfflineStatus {
    border: 3px solid #363636;
}

.sidebars.desk-sidebars.activeSidebars .userOnlineStatus,
.sidebars.desk-sidebars.activeSidebars .userOfflineStatus {
    border: 3px solid #e8f3ff;
}

.dark .sidebars.desk-sidebars.activeSidebars .userOnlineStatus,
.dark .sidebars.desk-sidebars.activeSidebars .userOfflineStatus {
    border: 3px solid #1c4778;
}

.dark .userOnlineStatus,
.dark .userOfflineStatus {
    border: 3px solid #000;
}

.userOnlineStatus {
    background-color: #7bc9aa;
}

.dark .userOnlineStatus {
    background-color: #56d6a3;
}

.userOfflineStatus {
    background-color: #9b9b9b;
}

.dark .userOfflineStatus {
    background-color: #868686;
}

.sidebars {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 16px 8px 16px;
    margin: 0 auto;
    position: relative;
    cursor: pointer;
    text-decoration: none;
    border: 1px solid #ffffff;
    border-bottom: 1px solid #0000001b;
    transition: background-color 0.3s ease;
}

.dark .sidebars {
    border: 1px solid #000000;
    border-bottom: 1px solid #ffffff23;
}

.sidebars:hover {
    background-color: #f7f7f7;
    border: 1px solid #f7f7f7;
}

.dark .sidebars:hover {
    background-color: #363636;
    border: 1px solid #363636;
}

.sidebars.activeSidebars {
    background-color: #e8f3ff;
    border: 1px solid #e8f3ff;
}

.dark .sidebars.activeSidebars {
    background-color: #1c4778;
    border: 1px solid #1c4778;
}

.desk-sidebars.sidebars {
    width: 95%;
    padding: 8px 16px;
    margin-bottom: 0;
}

.desk-sidebars.sidebars:hover {
    background-color: #f7f7f7;
    border: 1px solid #f7f7f7;
    border-radius: 10px;
}

.dark .desk-sidebars.sidebars:hover {
    background-color: #363636;
    border: 1px solid #363636;
    border-radius: 10px;
}

.desk-sidebars.sidebars.activeSidebars {
    background-color: #e8f3ff;
    border: 1px solid #e8f3ff;
    border-radius: 10px;
}

.dark .desk-sidebars.sidebars.activeSidebars {
    background-color: #1c4778;
    border: 1px solid #1c4778;
    border-radius: 10px;
}

.dark .desk-sidebars.sidebars.activeSidebars .sidebarwrt>p {
    color: #afafaf;
}

button {
    cursor: pointer;
    text-decoration: none;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

button:active {
    transform: scale(0.90);
}

.press {
    cursor: pointer;
    text-decoration: none;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.press:active {
    transform: scale(0.90);
}

a {
    cursor: pointer;
    text-decoration: none;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

a:active {
    transform: scale(0.92);
}

.sidebarwrt {
    width: 100%;
    line-height: 1;
    padding: 0px 0px 8px 8px;
}

.sidebarwrt h3 {
    line-height: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.sidebarwrt>p {
    font-size: 12px;
    color: grey;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.4;
}

.userhdinfowrt>p,
.userhdinfowrt>h3 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media screen and (min-width: 799px) {

    .sidebarwrt h3,
    .sidebarwrt>p {
        width: 195px;
    }
}

@media screen and (min-width: 850px) {

    .sidebarwrt h3,
    .sidebarwrt>p {
        width: 230px;
    }
}

@media screen and (min-width: 1050px) {

    .sidebarwrt h3,
    .sidebarwrt>p {
        width: 250px;
    }
}

@media screen and (max-width: 350px) {

    .sidebarwrt h3,
    .sidebarwrt>p,
    .userhdinfowrt>p,
    .userhdinfowrt>h3 {
        width: 215px;
    }
}

@media screen and (max-width: 310px) {

    .sidebarwrt h3,
    .sidebarwrt>p,
    .userhdinfowrt>p,
    .userhdinfowrt>h3 {
        width: 200px;
    }
}

@media screen and (max-width: 300px) {

    .sidebarwrt h3,
    .sidebarwrt>p,
    .userhdinfowrt>p,
    .userhdinfowrt>h3 {
        width: 170px;
    }
}

@media screen and (max-width: 245px) {

    .sidebarwrt h3,
    .sidebarwrt>p,
    .userhdinfowrt>p,
    .userhdinfowrt>h3 {
        width: 120px;
    }
}


.sideBarMailNoticeContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 17px;
    height: 40px;
    flex-shrink: 0;
}

.sideBarMailNotice {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    background-color: #449dfc;
    color: #fff;
    font-weight: 600;
    font-size: 8px;
    border-radius: 50%;
}

.sidebarwrtHouse {
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: center;
    width: 100%;
}

.sidebarwrtContainer {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-self: center;
    justify-content: space-between;
}

.sidebarwrt>small,
.sidebarwrtSmall {
    display: flex;
    justify-content: right;
    margin-bottom: 0;
    text-align: right;
    width: 100%;
    position: relative;
    color: rgb(159, 159, 159);
    font-size: 10px;
    font-weight: 600;
}

.dark .sidebarwrt>small,
.dark .sidebarwrtSmall {
    display: flex;
    justify-content: right;
    margin-bottom: 0;
    text-align: right;
    width: 100%;
    position: relative;
    color: grey;
    font-size: 10px;
    font-weight: 600;
}

.deskchatbod {
    width: 60%;
    height: 100%;
    border-radius: 24px;
    background-color: #fff;
    border: 2px solid #0000001d;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
}

.dark .deskchatbod {
    background-color: #1a1a1a;
    border: 2px solid #1a1a1a;
}

@media screen and (min-width: 1100px) {
    .deskchatbod {
        width: 65%;
    }
}

.desktchatbodhduser {
    width: 100%;
    display: flex;
    height: 60px;
    position: relative;
    align-items: center;
    justify-content: left;
    padding: 0 0 0 10px;
}

.desktchatbodhd {
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    height: 60px;
    position: absolute;
    background-color: #ffffff;
    border-bottom: 1px solid #e8e7e7f1;
    align-items: center;
    justify-content: left;
    padding: 8px 12px;
    z-index: 200;
}

.dark .desktchatbodhd {
    background-color: #1a1a1a;
    border-bottom: 1px solid #4c4c4cd7;
}

.desktchatbody::-webkit-scrollbar-thumb {
    border-radius: 6px;
    cursor: pointer;
    background: rgba(90, 96, 124, 0.5);
    display: none;
}

.desktchatbody::-webkit-scrollbar {
    width: 8px;
    background: transparent;
    display: none;
}

.dark .linkbkss {
    color: white;
}

.desktchatbody {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    z-index: 100;
    position: relative;
    padding-top: 0;
    padding-bottom: 20px;
    align-self: flex-end;
}

.retrievalUpdate {
    margin-top: 40px;
    width: 60%;
    color: #000;
    text-align: center;
    font-size: 0.8em;
}


/*.Toastify__progress-bar--default {
    visibility: hidden !important;
}*/


.dark .retrievalUpdate {
    color: #fff;
}

.desktchatbodyTopNeighbor {
    height: 60px;
    width: 100%;
    flex-shrink: 0;
    display: block;
}

.desktchatbodyBottomNeighbor {
    height: 80px;
    width: 100%;
    flex-shrink: 0;
    display: block;
}

.desktchatbody:first-child {
    margin-top: auto;
}


.desktchatbody::-webkit-scrollbar-thumb {
    border-radius: 6px;
    cursor: pointer;
    background: rgba(90, 96, 124, 0.5);
    display: none;
}

.desktchatbody::-webkit-scrollbar {
    width: 8px;
    background: transparent;
    display: none;
}

.desktchatbodinput {
    width: 100%;
    height: 80px;
    padding: 16px;
    background-color: #fff;
    border-top: 1px solid #e8e7e7f1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 200;
}

.dark .desktchatbodinput {
    background-color: #1a1a1a;
    border-top: 1px solid #4c4c4cd7;
}

.chatBottomwrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.pmesssage>p.sessionStatusTextBottom,
.pmesssage>p.sessionStatusTextBottom {
    padding-top: 12px;
    padding-bottom: 8px;
}

.pmesssage>p.sessionStatusTextTop,
.messsage>p.sessionStatusTextTop {
    padding-top: 8px;
    padding-bottom: 12px;
}

.sessionStatusTextBottom {
    border-bottom: 1px solid #0000000c;
}

.sessionStatusTextTop {
    border-top: 1px solid #0000000c;
}

.dark .sessionStatusTextBottom {
    border-bottom: 1px solid #ffffff14;
}

.dark .sessionStatusTextTop {
    border-top: 1px solid #ffffff14;
}

.desktchatbodinputwrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-right: 10px;
    padding-left: 16px;
    background-color: #f2f2f2;
    border: 1px solid #e3e3e3f1;
    border-radius: 26px;
}

.dark .desktchatbodinputwrapper {
    background-color: #363636;
    border: 1px solid #4c4c4cd7;
}

.sendbtn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1a71d0;
    color: #fff;
    height: 32px;
    padding: 0 10px 0 15px;
    font-size: 11px;
    cursor: pointer;
    border-radius: 26px;
}

.sendbtn.disableSendBtn,
.sendbtn.disableSendBtn:hover {
    background-color: #5381b2;
    cursor: not-allowed;
    padding: 0 12px 0 8px;
}

.disableSendBtnSvg {
    opacity: 0.3;
    margin-right: 5px;
}

.sendbtn svg {
    height: 15px;
    width: 15px;
    margin-left: 5px;
    fill: #fff;
}

.sendbtn:hover {
    background-color: #1a7ee6;
}


.challengeRequeststatus {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.challengeRequeststatus span {
    color: #2c2c2c;
    opacity: 0.6;
    font-size: 11px;
}

.dark .challengeRequeststatus span {
    color: #eaeaea;
    opacity: 0.5;
}

.challengeRequeststatus svg {
    height: 32px;
    width: auto;
    margin-right: 10px;
    margin-left: 5px;
}

#send {
    margin-left: .5em;
}

.messsage {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 20px 16px 0 16px;
    position: relative;
}

.msg>small {
    position: relative;
    width: 100%;
    margin: 4px 0 2px 0;
    font-size: 10px;
    color: #ffffff;
    opacity: 0.8;
}

.bubbleUsername {
    text-transform: capitalize;
}

.msg {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: auto;
    max-width: 75%;
    position: relative;
    color: #fff;
    clear: both;
    background: #74b9ff;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    padding: 6px 20px;
}

.dark .msg {
    background: #4396eb;
}

.msg:before,
.msg:after {
    border-radius: 20px / 5px;
    content: '';
    display: block;
    position: absolute;
}

.msg:before {
    border: 10px solid transparent;
    bottom: 0px;
    left: -7px;
    z-index: -2;
}

.msg:after {
    border: 10px solid transparent;
    border-bottom-color: #74b9ff;
    bottom: 1px;
    left: -5px;
}

.dark .msg:after {
    border-bottom-color: #4396eb;
}

.msgbtn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
    margin-top: 8px;
}

.cnclform {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
    margin-top: 0;
}

.msgbtn .cnclbtn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    margin-top: 0;
}

.dec,
.acc {
    padding: 5px 20px;
    font-size: 11px;
    color: #fff;
    border: 1px solid #fcfcfcb3;
    cursor: pointer;
    border-radius: 12px;
}

.dark .dec,
.dark .acc {
    border: 1px solid #dbdbdbb3;
}

.dec {
    background-color: red;
}

.acc {
    background-color: rgb(16, 163, 89);
    margin-left: 15px;
}


.pmesssage {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    padding: 20px 16px 0 16px;
    position: relative;
}

.pmesssage>p,
.messsage>p {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #9b9b9b;
    width: 100%;
    font-size: 11px;
    text-align: center;
    padding-top: 8px;
    padding-bottom: 8px;
}

.dark .pmesssage>p {
    color: #868686;
}

.pmsg>small {
    text-align: right;
    position: relative;
    width: 100%;
    margin: 4px 0 2px 0;
    font-size: 10px;
    color: grey;
}

.pmsg {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: auto;
    max-width: 75%;
    position: relative;
    clear: both;
    background: #efefef;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    padding: 6px 20px;
}

.dark .pmsg {
    background: #363636;
    color: #fff;
}

.pmsg:before,
.pmsg:after {
    border-radius: 20px / 5px;
    content: '';
    display: block;
    position: absolute;
}

.pmsg:before {
    border: 10px solid transparent;
    bottom: 0px;
    left: -7px;
    z-index: -2;
}

.pmsg:after {
    border: 10px solid transparent;
    border-bottom-color: #f2f2f2;
    bottom: 1px;
    right: -5px;
}

.dark .pmsg:after {
    border-bottom-color: #363636;
}


.noContactText {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    font-size: 13px;
    margin-bottom: 10px;
}

.noContactText~a {
    display: flex;
    color: #0052FF;
    align-items: center;
    justify-content: center;
    width: 90%;
    font-size: 13px;
}

.dark .noContactText~a {
    color: #2da6fd;
}

.emptcont.emptcontContacts svg {
    fill: #7a7a7a;
}

.emptcont.chatDefaultDisplay {
    width: 100%;
    height: 100%;
}

.emptcont {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    color: #808080;
}

.emptcont.emptcontContacts {
    width: 100%;
    height: auto;
    font-size: 13px;
}

.emptcont.emptcontContacts svg {
    width: 130px;
    height: 130px;
    margin-bottom: 0;
}

#emptcontContactsSvg1 {
    fill: #fff;
}

#emptcontContactsSvg2 {
    fill: #777777;
}

.dark #emptcontContactsSvg2 {
    fill: #4f4f4f;
}

.noResultEmptcont.emptcont {
    width: 100%;
    height: auto;
    padding: 50px 20px;
}



.Toastify__toast.Toastify__toast-theme--null,
.div.Toastify .Toastify__toast,
div.Toastify .Toastify__toast-body,
div.Toastify .Toastify__toast-theme--light {
    font-size: 14px !important;
    color: #000 !important;
    background-color: #fff !important;
}

.dark .Toastify__toast.Toastify__toast-theme--null,
.dark div.Toastify .Toastify__toast,
.dark div.Toastify .Toastify__toast-body,
.dark div.Toastify .Toastify__toast-theme--light {
    color: #fff !important;
    background-color: #444 !important;
}

.Toastify .Toastify__toast-container .Toastify__toast--error .Toastify__toast-body {
    font-size: 16px !important;
}




.chatDefaultDisplay.emptcont {
    font-size: 14px;
    position: absolute;
}

.chatDefaultDisplay.emptcont svg {
    fill: #a8a8a8;
    height: 100px;
    width: 100px;
    margin-bottom: 15px;
}

#gmc {
    width: 4em;
    height: 4em;
    color: rgb(168, 168, 168);
}

.stickedbar {
    padding: 14px 16px 0 16px;
    width: 100%;
    max-width: 600px;
    position: fixed;
    background-color: white;
    border-bottom: 1px solid #C2C2C2;
    z-index: 1000;
}

.dark .stickedbar {
    background-color: #000;
    border-bottom: 1px solid #3a3a3a;
}

.searchinpt {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: #f2f2f2;
    border: 1.5px solid #fff;
    color: #363636;
    border-radius: 13px;
    padding: 5px 5px 5px 8px;
}

.searchinptbord {
    width: 100%;
    display: flex;
    align-items: center;
    background-color: transparent;
    border: 1.5px solid #000;
    color: #363636;
    border-radius: 13px;
    padding: 5px 5px 5px 8px;
    margin-bottom: 10px;
}

.dark .searchinpt {
    background-color: #363636;
    border: 1.5px solid #000;
    color: #eaeaea;
}

.dark .searchinptbord {
    background-color: transparent;
    border: 1.5px solid #eaeaea;
    color: #eaeaea;
}

.ssrch {
    width: 100%;
    display: flex;
    align-items: center;
    color: #363636;
    border-radius: 13px;
    padding: 5px 5px 5px 8px;
    background-color: transparent;
    border: 1.5px solid #dadada;
    transition: background-color 0.2s ease, border 0.2s ease;
}

.dark .ssrch {
    background-color: transparent;
    border: 1.5px solid #363636;
    transition: background-color 0.2s ease, border 0.2s ease;
    color: #eaeaea;
}

.searchinpt>input {
    width: 85%;
    background: transparent;
    border: none;
    outline-width: 0;
    font-size: 14px;
    zoom: 1;
    padding: 5px;
    color: #363636;
}

.searchinptbord>input {
    width: 85%;
    background: transparent;
    border: none;
    outline-width: 0;
    font-size: 14px;
    zoom: 1;
    padding: 5px;
    color: #363636;
}

.ssrch>input {
    width: 85%;
    background: transparent;
    border: none;
    outline-width: 0;
    font-size: 14px;
    zoom: 1;
    padding: 5px;
    color: #363636;
}

.dark .searchinpt>input {
    color: #eaeaea;
}

.dark .searchinptbord>input {
    color: #eaeaea;
}

.dark .ssrch>input {
    color: #eaeaea;
}

#srchh {
    width: .7em;
    height: .7em;
    color: grey;
}

.popdic {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 202;
}

.popdicwallpaper {
    width: 100%;
    height: 100%;
    background-color: #0000001b;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
}

.dark .popdicwallpaper {
    background-color: #0000006b;
}

.popdicwallpaperSearch.popdicwallpaper,
.dark .popdicwallpaperSearch.popdicwallpaper {
    background-color: rgba(0, 0, 0, 0);
}


.popdiccontainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 3;
}

.ppup {
    max-width: 350px;
    width: 100%;
    height: auto;
    color: #000;
    box-shadow: 2px 4px 14px -3px rgba(0, 0, 0, 0.596);
    border-radius: 20px;
    display: block;
    position: relative;
    z-index: 4;
}

.popUpInputs {
    width: 100%;
    padding: 30px 20px 35px 20px;
    background-color: #fff;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-top: 1px solid #0000000b;
    border-left: 1px solid #0000000b;
    border-right: 1px solid #0000000b;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.popUpSelectContainer {
    width: 100%;
    border: 1px solid #c0c0c0;
    border-radius: 10px;
    padding: 10px 15px 10px 10px;
    margin-bottom: 10px;
}

.ppupHeader {
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: left;
    width: 100%;
    margin-bottom: 10px;
}

.ppupHeader svg {
    width: 30px;
    height: 30px;
    stroke-Width: 1px;
    stroke: #000;
}

.ppupHeader span {
    font-size: 20px;
    font-weight: 700;
}

.ppupPlaceholder {
    width: 100%;
    font-size: 11px;
    font-weight: 600;
    margin: 5px auto;
    padding-left: 8px;
}

.popupselect {
    width: 100%;
    font-size: 13px;
    background-color: transparent;
    outline-width: 0;
    color: #000;
    outline: none;
    border: 0;
}

.dark .popupselect {
    color: #000;
}

.popupselect option,
.ppupPlaceholder,
.popupinput {
    color: #000;
}

.dark .popupselect option,
.dark .ppupPlaceholder,
.dark .popupinput {
    color: #000;
}

.popupinput {
    width: 100%;
    padding: 12px 10px;
    font-size: 13px;
    zoom: 1;
    outline-width: 0;
    background-color: transparent;
    border: none;
    overflow: hidden;
    border-radius: 10px;
    border: 1px solid #c0c0c0;
}

.popupbtn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    background-color: #fff;
    overflow: hidden;
}

.popupbtn button,
.popupbtn button:hover,
.popupbtn button:active,
.popupbtn button:focus {
    transform: scale(1);
}

.canclbtn {
    width: 100%;
    padding: 12px;
    font-size: 12px;
    color: rgb(223, 39, 39);
    background-color: transparent;
    border: none;
    border-top: 1px solid #c0c0c0;
    cursor: pointer;
}

.canclbtn:hover {
    background-color: #c0c0c0;
}

.sendbtn1 {
    width: 100%;
    padding: 12px;
    font-size: 12px;
    color: #000;
    background-color: transparent;
    border: none;
    border-top: 1px solid #c0c0c0;
    border-left: 1px solid #c0c0c0;
    cursor: pointer;
}

.sendbtn1:hover {
    background-color: #c0c0c0;
}

.inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    padding: 0;
    margin: 0;
    font-size: 13px;
    word-wrap: break-word;
}

.inner>P {
    word-wrap: break-word;
    line-height: 1.4;
}

.inner>P::first-letter {
    text-transform: uppercase;
}

.firsttime {
    width: 190px;
    height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
}

.firsttime>p {
    font-weight: 700;
    color: white;
    width: 100%;
    text-align: center;
}

.searhBoardContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    position: relative;
}

.desktoppsrch {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
    overflow-y: scroll;
    position: relative;
}

.desktoppsrch::-webkit-scrollbar-thumb {
    border-radius: 6px;
    cursor: pointer;
    background: rgba(90, 96, 124, 0.5);
    display: none;
}

.desktoppsrch::-webkit-scrollbar {
    width: 8px;
    background: transparent;
    display: none;
}

.desktoppsrchbod {
    width: 100%;
    max-width: 800px;
    border: none;
}

.load {
    width: 5em;
    height: 5em;
}

.mobsrchbod,
.searhBoardMob {
    position: relative;
    display: none;
}

.avatarbod {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f1f1f1;
    border-radius: 50%;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
}

.avatarbod div {
    width: 30px;
    height: 30px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.svg0 {
    background-image: url('/src/img/0.svg');
}

.svg1 {
    background-image: url('/src/img/1.svg');
}

.svg2 {
    background-image: url('/src/img/2.svg');
}

.svg3 {
    background-image: url('/src/img/3.svg');
}

.svg4 {
    background-image: url('/src/img/4.svg');
}

.svg5 {
    background-image: url('/src/img/5.svg');
}

.svg6 {
    background-image: url('/src/img/6.svg');
}

.svg7 {
    background-image: url('/src/img/7.svg');
}

.svg8 {
    background-image: url('/src/img/8.svg');
}

.svg9 {
    background-image: url('/src/img/9.svg');
}

.svg10 {
    background-image: url('/src/img/10.svg');
}

.ldd {
    width: 2em;
    height: 2em;
}

.smlld {
    width: 1.1em;
    height: 1.1em;
    margin: 5px;
}

.lot {
    display: flex;
    align-items: center;
}



@media only screen and (max-width: 800px) {

    .dsksrch,
    .searhBoardDesk {
        display: none;
    }

    .dsksrchactive {
        display: none;
    }

    .desktchat {
        display: none;
    }

    .mobsrchbod,
    .searhBoardMob {
        display: flex;
    }

    /* .desktoppsrch {
        display: none;
    } */
}