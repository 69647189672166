.bottom {
    position: fixed;
    bottom: 0;
    background-color: white;
    width: 100%;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    border-top: 1px solid #c2c2c2;
    z-index: 10000;
}

.dark .bottom {
    background-color: black;
    border-top: 1px solid #3a3a3a;
}

.bottombod {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;

}

.bottombod svg {
    fill: #000;
}

.dark .bottombod svg {
    fill: #ddd;
}

.bottomBarText {
    margin-top: 3px;
}

.bottomlinks {
    background-color: transparent;
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: black;
    font-size: 9px;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    position: relative;
    text-decoration: none;
    outline: none;
    outline-width: 0;
}

.bottomlinks:focus,
.bottomlinksL:focus,
.bottomlinks:active,
.bottomlinksL:active {
    transform: scale(0.96);
    background-color: transparent;
    text-decoration: none;
    outline: none;
}

.dark .bottomlinks {
    color: white;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.dark .bottomlinksL {
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    transition: 1s;
}

.bottomlinksL {
    background-color: transparent;
    width: 25%;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    outline: none;
    align-items: center;
    justify-content: center;
    color: grey;
    font-size: 9PX;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    position: relative;
    opacity: .6;
    transition: 1s;
}

.dark .bottomlinksL {
    color: rgb(192, 192, 192);
}

.notnum {
    display: flex;
    width: fit-content;
    height: 1.5em;
    position: relative;
}

.notnumdark {
    display: flex;
    width: fit-content;
    height: 1.5em;
    position: relative;
}

.notnum>div {
    position: absolute;
    top: -.25em;
    right: -.3em;
    background-color: red;
    color: white;
    width: 1.2em;
    height: 1.2em;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 2px solid white;
}

.notnumdark>div {
    position: absolute;
    top: -.25em;
    right: -.3em;
    background-color: red;
    color: white;
    width: 1.2em;
    height: 1.2em;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 2px solid black;
}

.notnum>div>p {
    font-size: 10px;
}

.notnumdark>div>p {
    font-size: 10px;
}

.tube {
    width: 25%;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    transition: left 400ms ease;
}

.tubelight {
    /* transform: translateX(-20%); */
    width: 65%;
    height: 5px;
    border-radius: 5px;
    background: #0865fe;
    display: flex;
    justify-content: center;
}

@media screen and (min-width: 800px) {
    .tubelight {
        width: 40%;
    }
}

.bttm2 {
    display: none;
}



@media only screen and (max-width: 800px) {
    .bttm2 {
        display: flex;
    }

    .tube {
        width: 25%;
        position: absolute;
        left: 6.3em;
        top: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .light-ray {
        width: 350%;
    }
}

.light-ray {
    width: 200%;
    clip-path: polygon(5% 100%, 20% 0px, 80% 0px, 95% 100%);
    background: linear-gradient(to bottom, #3482ff -90%, rgba(255, 255, 255, 0) 100%);
    pointer-events: none;
    z-index: -1;
}

@media only screen and (max-width: 800px) {
    .light-ray {
        width: 350%;
    }
}

/* Mob view */
.bottomBarHeight {
    flex-shrink: 0;
}

/* iOS */
.iosview .bottomBarHeight {
    height: 52px;
}

.iosview .tubelight {
    margin-bottom: 52px;
}

.iosview .light-ray {
    height: 52px;
}

.iosview .bottomBarHeight #bttmmicons {
    height: 23px;
    width: 23px;
}


/* Android */
.androidView .bottomBarHeight {
    height: 56px;
}

.androidView .tubelight {
    margin-bottom: 56px;
}

.androidView .light-ray {
    height: 58px;
}

.androidView .bottomBarHeight #bttmmicons {
    height: 24px;
    width: 24px;
}